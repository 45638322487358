@import '../../scss/mixins';

.ate-static-course-list-page {
    @include paddingAmongBreadcrumb;
    padding-bottom: var(--ui-spacing-lg);

    .spinner {
        padding-top: var(--ui-spacing-md);
    }
}
