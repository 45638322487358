.infinite-scroll {
    width: 100%;

    .loader {
        display: flex;
        justify-content: center;
        padding: 20px 0;
        width: 100%;
    }
}
