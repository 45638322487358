.databrowser-sort-panel {
    .sort-list {
        display: none;
        flex-direction: column;
        position: absolute;
        min-width: 130px;
        border-radius: var(--ate-border-radius);
        margin-top: var(--ui-spacing-xxs);
        padding: var(--ui-spacing-tiny);
        padding-inline-end: var(--ui-spacing-xs);
        z-index: 2;
        border: 1px solid var(--ate-widget-border);

        .single-sort {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 32px;
            cursor: pointer;
            border-radius: 4px;
            padding-inline: 4px;

            &:hover {
                background: rgb(237, 235, 233);
            }

            .ui-icon {
                margin-left: var(--ui-spacing-xxs);
            }
        }
    }

    &.active {
        .sort-list {
            display: flex;
        }
    }
}

.dark {
    .databrowser-sort-panel {
        .single-sort:hover {
            background: rgb(59, 58, 57);
        }
    }
}

.contrast {
    .databrowser-sort-panel {
        .single-sort:hover {
            background: rgb(26, 235, 255);
        }
    }
}
