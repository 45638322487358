.ate-illustration {
  display: inline-block
}

.ate-illustration #color-primary {
  fill: var(--ui-color-accent-primary)
}

.ate-illustration.ate-illustration-size-xxs, .ate-illustration.ate-illustration-size-xxs span, .ate-illustration.ate-illustration-size-xxs svg {
  height: 3rem;
  height: var(--ui-size-illustration-xxs)
}

.ate-illustration.ate-illustration-size-xs, .ate-illustration.ate-illustration-size-xs span, .ate-illustration.ate-illustration-size-xs svg{
  height: 4.5rem;
  height: var(--ui-size-illustration-xs)
}

.ate-illustration.ate-illustration-size-sm, .ate-illustration.ate-illustration-size-sm span, .ate-illustration.ate-illustration-size-sm svg {
  height: 6.5rem;
  height: var(--ui-size-illustration-sm)
}

.ate-illustration.ate-illustration-size-md, .ate-illustration.ate-illustration-size-md span, .ate-illustration.ate-illustration-size-md svg {
  height: 12.75rem;
  height: var(--ui-size-illustration-md)
}

.ate-illustration.ate-illustration-size-lg, .ate-illustration.ate-illustration-size-lg span, .ate-illustration.ate-illustration-size-lg svg {
  height: 18.5rem;
  height: var(--ui-size-illustration-lg)
}

.ate-illustration.ate-illustration-size-xl, .ate-illustration.ate-illustration-size-xl span, .ate-illustration.ate-illustration-size-xl svg {
  height: 28rem;
  height: var(--ui-size-illustration-xl)
}

.ate-illustration.ate-illustration-size-full, .ate-illustration.ate-illustration-size-full span, .ate-illustration.ate-illustration-size-full svg {
  height: 100%;
  height: var(--ui-size-illustration-full)
}

.ate-illustration span {
  display: flex
}
