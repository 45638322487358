@import './components/scss/_designTokens.scss';

#dcb-for-msteams {
    margin: 0 auto;
    overflow: hidden;
}

.dcb-ui-tooltips-root {
  height: 0;
}
