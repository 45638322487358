:root {
    --ate-border-radius: 3px;
    --ate-breadcrumb-height: 56px;
}

.default {
    --ate-color-1: #FFFFFF;
    --ate-color-2: #F5F5F5;
    --ate-color-3: #212721;
    --ate-color-4: #666666;
    --ate-color-5: #0465AC;
    --ate-color-6: #E4E6E5;
    --ate-color-7: rgb(0, 0, 0, 0.08);
    --ate-color-8: #BC2F32;

    --ate-background-color: var(--ate-color-1);
    --ate-widget-border: var(--ate-color-2);
    --ate-widget-border-style: 1px solid var(--ate-color-2);
    --ate-heading-color: var(--ate-color-3);
    --ate-text-color: var(--ate-color-4);
    --ate-expiration-date-color: var(--ate-color-8);
    --ate-horizontal-tab-active: var(--ate-color-5);
    --ate-selection-color: var(--ate-color-5);
    --ate-course-player-border: var(--ate-color-6);
    --ate-course-player-link: var(--ate-color-5);
    --ate-course-player-item-hover-heading: var(--ate-color-3);
    --ate-course-player-item-hover-text: var(--ate-color-4);
    --ate-course-player-item-hover-background: var(--ate-color-7);
    --ate-course-player-item-hover-border: var(--ate-color-4);
    --ate-course-player-item-hover-disabled: var(--ate-color-4);
    --ate-course-player-item-hover-enabled: var(--ate-color-7);
    --ate-button-opacity-disabled: 0.5;
    height: 100%;
}

.dark {
    --ate-color-1: #2D2C2C;
    --ate-color-2: #000000;
    --ate-color-3: #FFFFFF;
    --ate-color-4: #7F85F5;
    --ate-color-5: #E4E6E5;
    --ate-color-6: rgb(255, 255, 255, 0.08);
    --ate-color-7: rgb(0, 0, 0, 0.08);
    --ate-color-8: #E37D80;

    --ate-background-color: var(--ate-color-1);
    --ate-widget-border: var(--ate-color-2);
    --ate-widget-border-style: 1px solid var(--ate-color-2);
    --ate-heading-color: var(--ate-color-3);
    --ate-text-color: var(--ate-color-3);
    --ate-expiration-date-color: var(--ate-color-8);
    --ate-horizontal-tab-active: var(--ate-color-3);
    --ate-selection-color: var(--ate-color-4);
    --ate-course-player-border: var(--ate-color-5);
    --ate-course-player-link: var(--ate-color-4);
    --ate-course-player-item-hover-heading: var(--ate-color-3);
    --ate-course-player-item-hover-text: var(--ate-color-3);
    --ate-course-player-item-hover-background: var(--ate-color-6);
    --ate-course-player-item-hover-border: var(--ate-color-3);
    --ate-course-player-item-hover-disabled: var(--ate-color-3);
    --ate-course-player-item-hover-enabled: var(--ate-color-7);
    --ate-button-opacity-disabled: 0.5;
    height: 100%;
}

.contrast {
    --ate-color-1: #000000;
    --ate-color-2: #FFFFFF;
    --ate-color-3: #1AEBFF;
    --ate-color-4: #E4E6E5;
    --ate-color-5: #3FF23F;

    --ate-background-color: var(--ate-color-1);
    --ate-widget-border: var(--ate-color-2);
    --ate-widget-border-style: 0;
    --ate-heading-color: var(--ate-color-2);
    --ate-text-color: var(--ate-color-2);
    --ate-expiration-date-color: var(--ate-color-2);
    --ate-horizontal-tab-active: var(--ate-color-2);
    --ate-selection-color: var(--ate-color-3);
    --ate-course-player-border: var(--ate-color-4);
    --ate-course-player-link: var(--ate-color-5);
    --ate-course-player-item-hover-heading: var(--ate-color-1);
    --ate-course-player-item-hover-text: var(--ate-color-1);
    --ate-course-player-item-hover-background: var(--ate-color-3);
    --ate-course-player-item-hover-border: var(--ate-color-3);
    --ate-course-player-item-hover-disabled: var(--ate-color-5);
    --ate-course-player-item-hover-enabled: initial;
    --ate-button-opacity-disabled: 1;
    --ate-custom-content-box-high-contrast-button-background: var(--ate-color-2);
    --ate-custom-content-box-high-contrast-button-text: var(--ate-color-1);
    --ate-custom-content-box-high-contrast-button-hover-background: var(--ate-color-3);
    --ate-custom-content-box-high-contrast-button-hover-text: var(--ate-color-1);
    height: 100%;
}
