.dashboard-page {
    height: 100%;
    padding-block: var(--ui-spacing-md);

    .dashboard-row {
        margin-bottom: var(--ui-spacing-sm);
        width: 100%;

        &:last-child {
            padding-bottom: var(--ui-spacing-md);
        }

        &.mobile {
            .column {
                &:not(:first-child) {
                    margin-top: var(--ui-spacing-sm);
                }
            }
        }

        .widget {
            &:not(:first-child) {
                margin-top: var(--ui-spacing-sm);
            }
        }
    }

    .empty-dashboard-blankslate {
        height: 100%;
    }

    .spinner {
        margin-block: var(--ui-spacing-md);
    }
}
