.dialog-mobile {
    width: 100% !important;
    height: 100% !important;
}

.sub-policies-section {
    display: flex;
    flex-direction: column;
}

.dismiss-button {
    cursor: pointer;
}
