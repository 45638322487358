$iconContainerWidth: 50px;

.ate-static-course-list-grouped-view {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    box-shadow: var(--shadow4) !important;
    padding: var(--ui-spacing-md) !important;
    border-radius: var(--ate-border-radius) !important;
    border: 1px solid var(--ate-widget-border) !important;
    cursor: pointer;

    .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--ui-radius-circular);
        background-position: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        height: $iconContainerWidth;
        min-width: $iconContainerWidth;
        max-width: $iconContainerWidth;
    }

    .title-container {
        padding-left: var(--ui-spacing-sm);

        .title {
            font-size: var(--ui-typography-size-md);
        }
    }
}
