.flow-ms-teams {
    height: 100vh;

    .main-container {
        height: 100%;
        width: 100%;
        overflow: auto;

        &.breadcrumb-visible {
            height: calc(100% - var(--ate-breadcrumb-height));
        }

        //This rule is useful to the course player page
        //since to remove padding between player and breadcrumb
        &:has(.course-player-container) {
            padding: 0;
            overflow: hidden;
        }
    }
}
