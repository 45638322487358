.ate-icon {
  display: inline-block;
}

.ate-icon span.color-accent svg {
  fill: #0465ac;
  fill: var(--ui-color-icon-accent-primary);
}

.ate-icon span.color-dark svg {
  fill: #333333;
  fill: var(--ui-color-icon-dark);
}

.ate-icon span.color-neutral svg {
  fill: #707070;
  fill: var(--ui-color-icon-neutral);
}

.ate-icon span.color-negative svg {
  fill: white;
  fill: var(--ui-color-icon-negative);
}

.ate-icon span.color-success svg {
  fill: #008767;
  fill: var(--ui-color-icon-success);
}

.ate-icon span.color-alert svg {
  fill: #ffc74f;
  fill: var(--ui-color-icon-alert);
}

.ate-icon span.color-error svg {
  fill: #cf000f;
  fill: var(--ui-color-icon-error);
}

.ate-icon span.color-rating svg {
  fill: #008767;
  fill: var(--ui-color-icon-rating);
}

.ate-icon span.color-google svg {
  fill: #db4437;
  fill: var(--ui-color-third-party-google);
}

.ate-icon span.color-facebook svg {
  fill: #3c5a99;
  fill: var(--ui-color-third-party-facebook);
}

.ate-icon span.color-twitter svg {
  fill: #1da1f2;
  fill: var(--ui-color-third-party-twitter);
}

.ate-icon span.color-zendesk svg {
  fill: #78a300;
  fill: var(--ui-color-third-party-zendesk);
}

.ate-icon span.color-okta svg {
  fill: #009cdd;
  fill: var(--ui-color-third-party-okta);
}

.ate-icon span.color-auth0 svg {
  fill: #eb5323;
  fill: var(--ui-color-third-party-auth0);
}

.ate-icon span.color-paypal svg {
  fill: #003087;
  fill: var(--ui-color-third-party-paypal);
}

.ate-icon span.color-drive svg {
  fill: #008744;
  fill: var(--ui-color-third-party-drive);
}

.ate-icon span.color-linkedin svg {
  fill: #0077b5;
  fill: var(--ui-color-third-party-linkedin);
}

.ate-icon span.color-openid svg {
  fill: #ff6202;
  fill: var(--ui-color-third-party-openid);
}

.ate-icon span.color svg {
  fill: #ff6202;
}

.ate-icon.ate-icon-size-xxs,
.ate-icon.ate-icon-size-xxs span,
.ate-icon.ate-icon-size-xxs svg {
  width: 0.75rem;
  width: var(--ui-size-icon-xxs);
  height: 0.75rem;
  height: var(--ui-size-icon-xxs);
}

.ate-icon.ate-icon-size-xs,
.ate-icon.ate-icon-size-xs span,
.ate-icon.ate-icon-size-xs svg {
  width: 1rem;
  width: var(--ui-size-icon-xs);
  height: 1rem;
  height: var(--ui-size-icon-xs);
}

.ate-icon.ate-icon-size-sm,
.ate-icon.ate-icon-size-sm span,
.ate-icon.ate-icon-size-sm svg {
  width: 1.25rem;
  width: var(--ui-size-icon-sm);
  height: 1.25rem;
  height: var(--ui-size-icon-sm);
}

.ate-icon.ate-icon-size-md,
.ate-icon.ate-icon-size-md span,
.ate-icon.ate-icon-size-md svg {
  width: 1.5rem;
  width: var(--ui-size-icon-md);
  height: 1.5rem;
  height: var(--ui-size-icon-md);
}

.ate-icon.ate-icon-size-lg,
.ate-icon.ate-icon-size-lg span,
.ate-icon.ate-icon-size-lg svg {
  width: 2rem;
  width: var(--ui-size-icon-lg);
  height: 2rem;
  height: var(--ui-size-icon-lg);
}

.ate-icon.ate-icon-size-xl,
.ate-icon.ate-icon-size-xl span,
.ate-icon.ate-icon-size-xl svg {
  width: 2.5rem;
  width: var(--ui-size-icon-xl);
  height: 2.5rem;
  height: var(--ui-size-icon-xl);
}

.ate-icon.ate-icon-size-xxl,
.ate-icon.ate-icon-size-xxl span,
.ate-icon.ate-icon-size-xxl svg {
  width: 3.5rem;
  width: var(--ui-size-icon-xxl);
  height: 3.5rem;
  height: var(--ui-size-icon-xxl);
}

.ate-icon.ate-icon-size-xxxl,
.ate-icon.ate-icon-size-xxxl span,
.ate-icon.ate-icon-size-xxxl svg {
  width: 4.5rem;
  width: var(--ui-size-icon-xxxl);
  height: 4.5rem;
  height: var(--ui-size-icon-xxxl);
}

.ate-icon span {
  display: flex;
}
