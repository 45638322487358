@import '../../scss/mixins.scss';

.custom-content-box {
    background-position: center;
    background-size: cover;
    position: relative;
    border-radius: var(--ate-border-radius);
    border: 1px solid var(--ate-widget-border);
    color: var(--ui-color-typography-negative);
    box-shadow: var(--shadow4);

    .content-container {
        box-sizing: border-box;
        position: absolute;
        height: 100%;
        width: 100%;
        padding: var(--ui-spacing-lg);
    }

    .keep-aspect-ratio {
        width: 100%;
        height: auto;
        display: block;
    }

    .title-description-wrapper {
        gap: var(--ui-spacing-xxs);

        .ellipsis {
            @include text-ellipsis(4);
        }
    }

    .description-container {
        margin-top: var(--ui-spacing-xxs);
    }

    .custom-button {
        margin-top: var(--ui-spacing-xs);
        min-height: var(--ui-spacing-lg);

        &.button-default {
            color: var(--text-color);
            background-color: var(--background-color);
            transition: 0.20s background-color;

            &:hover {
                background-color: var(--background-color-hover-darken);
                color: var(--text-color);
                transition: 0.20s background-color;

                &:active {
                    background-color: var(--background-color-active-darken);
                    transition: 0.20s background-color;
                }
            }
        }

        &.button-dark {
            color: var(--text-color);
            background-color: var(--background-color);
            transition: 0.20s background-color;

            &:hover {
                background-color: var(--background-color-hover-lighten);
                color: var(--text-color);
                transition: 0.20s background-color;

                &:active {
                    background-color: var(--background-color-active-darken);
                    transition: 0.20s background-color;
                }
            }
        }
    }
}
