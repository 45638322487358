@import "../../../scss/variables";

.ate-static-course-list-ungrouped-view {
    & .content {
        margin: 0 calc(var(--ui-spacing-xxs) * -1);
        @media (min-width: $breakpoint-xlg) {
            margin: 0 calc(var(--ui-spacing-xs) * -1);
        }

        @for $i from 1 through 10 {
            &.cards-container-grid-#{$i} {
                margin-top: var(--ui-spacing-tiny);
                .card-container {
                    width: calc(100% / $i);
                }
            }
        }

        & .card-container {
            display: inline-flex;
            box-sizing: border-box;
            padding: var(--ui-spacing-xxs);
            @media (min-width: $breakpoint-xlg) {
                padding: var(--ui-spacing-xs);
            }
        }

        & .card-list {
            margin-bottom: 1px;
        }

        &.cards-list-container {
            display: flex;
            flex-direction: column;
            margin-top: var(--ui-spacing-xs);
        }
    }
}
