.databrowser {
    .databrowser-top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: var(--ate-border-radius);
        border: 1px solid var(--ate-widget-border);
        height: 48px;
        padding: 0 var(--ui-spacing-xxs);
        position: relative;

        .databrowser-filter-container {
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;

            &.strech-mode {
                .filter {
                    padding-right: var(--ui-spacing-xxs);
                    padding-left: 0;
                }
            }
        }

        .search-full-mode {
            display: flex;
            align-items: center;
            width: 100%;

            .back-button {
                padding: 0 var(--ui-spacing-xs);
            }

            .input {
                width: 100%;
            }
        }
    }

    .databrowser-loader {
        padding: var(--ui-spacing-lg);
    }

    .view-all {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &.mobile {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            padding: var(--ui-spacing-sm) 0;
        }
    }
}
