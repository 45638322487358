.filters-container {
    &:not(.search-exploded) > * {
        padding: 0 var(--ui-spacing-xs);

        &:nth-child(2n-1) {
            padding: unset;
        }
    }

    &.stretch-mode {
        .sort-list,
        .panel-container {
            right: 0;
        }
    }
}
