.single-course-player-preview {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 1) 85%), var(--course-background-image);
    background-position: center;
    background-size: cover;
    padding: var(--ui-spacing-lg);
    cursor: pointer;
    border-radius: var(--ate-border-radius);
    border: 1px solid var(--ate-widget-border);
    box-shadow: var(--shadow4);
    position: relative;

    .course-info {
        & > :not(:first-child) {
            margin-top: var(--ui-spacing-xxs);
        }

        .course-name,
        .course-type,
        .course-other-info {
            color: var(--ui-color-typography-negative);
        }

        .course-other-info {
            .divider {
                height: var(--ui-spacing-sm);
                margin: 0 var(--ui-spacing-xs);
            }

            .course-rating {
                margin-left: var(--ui-spacing-xs);
                .rating {
                    margin-left: var(--ui-spacing-tiny);
                }
                .ate-icon {
                    margin-bottom: var(--ui-spacing-micro);
                }
            }
        }

        .course-type {
            .course-type-label {
                margin-left: var(--ui-spacing-xs);
            }
        }
    }

    .menu {
        position: absolute;
        top: 0;
        right: 0;
        margin: var(--ui-spacing-xs);
    }
}
