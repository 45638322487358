@import "../../../scss/mixins";
@import '../../../scss/variables';

.ate-card {
  padding: 0 !important;
  width: 100% !important;
  border: var(--ate-widget-border-style);

  &.locked {
    cursor: default;

    .container {
      display: flex;
      align-items: center;
      background: var(--ui-color-typography-primary);
      border-radius: 50%;
      padding: var(--ui-spacing-xs);
    }
  }

  &.stacked {
    box-shadow:
            /* The top layer shadow */
            0 -1px 1px rgba(0,0,0,0.15),
              /* The second layer */
            0 10px 0 -5px #eee,
              /* The second layer shadow */
            0 10px 1px -4px rgba(0,0,0,0.15),
              /* The third layer */
            0 20px 0 -10px #eee,
              /* The third layer shadow */
            0 20px 1px -9px rgba(0,0,0,0.15);
  }

  & .card-preview-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 80px;

    & .card-image {
      background-image: var(--course-background-image);
      background-position: center;
      background-size: cover;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      width: 100%;
    }

    & .locked {
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;

      & .locked-icon {
        color: white;
      }
    }

    & .card-left-label {
      position: absolute;
      left: var(--ui-spacing-tiny);
      top: var(--ui-spacing-tiny);
    }

    & .card-right-label {
      position: absolute;
      right: var(--ui-spacing-tiny);
      top: var(--ui-spacing-tiny);
    }
  }

  & .card-body {
    height: 99px;
    padding: var(--ui-spacing-xs);

    & .card-title {
      @include text-ellipsis(3);
    }

    & .card-available {
      @include text-ellipsis(2);
      margin-bottom: var(--ui-spacing-sm);
    }

    & .card-details-divider {
      margin: 0 var(--ui-spacing-xs);
    }

    & .card-rating {
      .number {
        margin-left: var(--ui-spacing-tiny);
      }

      .ate-icon {
        margin-bottom: var(--ui-spacing-micro);
      }
    }
  }

  & .card-footer {
    height: var(--ui-spacing-xl);
    margin: 0;
    padding: 0 var(--ui-spacing-xs);

    & .card-footer-label {
      margin-left: var(--ui-spacing-xxs);
    }
  }
}

@media only screen and (min-width: $breakpoint-lg) {
  .ate-card {
    & .card-preview-container {
      height: 121px;
    }

    & .card-body {
      height: 127px;
    }
  }
}
