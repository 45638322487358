/* ui-style from @docebo/ui 1.101.0 */

@use 'sass:math';

.ui-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;

  // Avoid browsers rendering the focus ring in some cases.
  outline: 0;

  // Avoid some cases where the browser will still render the native controls.
  -webkit-appearance: none;
  -moz-appearance: none;
}


.ui-background-backdrop {
  background-color: var(--ui-color-layout-background);
}

.ui-background-surface {
  background-color: var(--ui-color-layout-surface);
}

.ui-background-contrast {
  background-color: var(--ui-color-layout-contrast);
}

.ui-background-overlay-dark {
  background-color: var(--ui-color-layout-overlay-dark);
}

.ui-background-overlay-light {
  background-color: var(--ui-color-layout-overlay-light);
}

.ui-background-third-party-google {
  background-color: var(--ui-color-third-party-google);
}

.ui-background-third-party-facebook {
  background-color: var(--ui-color-third-party-facebook);
}

.ui-background-third-party-twitter {
  background-color: var(--ui-color-third-party-twitter);
}

.ui-background-third-party-zendesk {
  background-color: var(--ui-color-third-party-zendesk);
}

.ui-background-third-party-okta {
  background-color: var(--ui-color-third-party-okta);
}

.ui-background-third-party-auth {
  background-color: var(--ui-color-third-party-auth-0);
}

.ui-background-third-party-paypal {
  background-color: var(--ui-color-third-party-paypal);
}

.ui-background-third-party-drive {
  background-color: var(--ui-color-third-party-drive);
}

.ui-background-third-party-linkedin {
  background-color: var(--ui-color-third-party-linkedin);
}

/* Dialogs background */
.ui-background-dialog-light {
  background-color: var(--ui-color-dialog-background-light);
}

.ui-background-dialog-accent {
  background-color: var(--ui-color-dialog-background-accent);
}

.ui-background-dialog-dark {
  background-color: var(--ui-color-dialog-background-dark);
}

.ui-background-dialog-neutral {
  background-color: var(--ui-color-dialog-background-neutral);
}

.ui-background-dialog-soft {
  background-color: var(--ui-color-dialog-background-soft);
}

.ui-background-dialog-success {
  background-color: var(--ui-color-dialog-background-success);
}

.ui-background-dialog-alert {
  background-color: var(--ui-color-dialog-background-alert);
}

.ui-background-dialog-error {
  background-color: var(--ui-color-dialog-background-error);
}

.ui-border-accent-primary {
  border: solid 1px var(--ui-color-border-accessibility-default);
}

.ui-border-accent-secondary {
  border: solid 1px var(--ui-color-border-accent-secondary);
}

.ui-border-accent-tertiary {
  border: solid 1px var(--ui-color-border-accent-tertiary);
}

.ui-border-error {
  border: solid 1px var(--ui-color-border-error);
}

.ui-border-neutral {
  border: solid 1px var(--ui-color-border-neutral);
}

.ui-border-negative {
  border: solid 1px var(--ui-color-border-negative);
}

.ui-border-radius-default {
  border-radius: var(--ui-radius-default);
}

.ui-border-radius-circular {
  border-radius: var(--ui-radius-circular);
}

.ui-border-radius-pill {
  border-radius: var(--ui-radius-pill);
}

.ui-color-semantic-success {
  color: var(--ui-color-semantic-success);
}

.ui-color-semantic-alert {
  color: var(--ui-color-semantic-alert);
}

.ui-color-semantic-error-default {
  color: var(--ui-color-semantic-error-default);
}

.ui-color-typography-primary {
  color: var(--ui-color-typography-primary);
}

.ui-color-typography-secondary {
  color: var(--ui-color-typography-secondary);
}

.ui-color-typography-error {
  color: var(--ui-color-typography-error);
}

.ui-color-typography-error-reverse {
  color: var(--ui-color-typography-errorreverse);
}

.ui-color-typography-success {
  color: var(--ui-color-typography-success);
}

.ui-color-typography-alert {
  color: var(--ui-color-typography-alert);
}

.ui-color-typography-negative {
  color: var(--ui-color-typography-negative);
}

.ui-color-typography-accent {
  color: var(--ui-color-typography-accent);
}

.ui-color-icon-accent {
  color: var(--ui-color-icon-accent-primary);
}

.ui-color-icon-neutral {
  color: var(--ui-color-icon-neutral);
}

.ui-color-icon-negative {
  color: var(--ui-color-icon-negative);
}

.ui-color-icon-alert {
  color: var(--ui-color-icon-alert);
}

.ui-color-icon-rating {
  color: var(--ui-color-icon-rating);
}

.ui-color-icon-error {
  color: var(--ui-color-icon-error);
}

.ui-color-icon-success {
  color: var(--ui-color-icon-success);
}

.ui-color-third-party-google {
  color: var(--ui-color-third-party-google);
}

.ui-color-third-party-facebook {
  color: var(--ui-color-third-party-facebook);
}

.ui-color-third-party-twitter {
  color: var(--ui-color-third-party-twitter);
}

.ui-color-third-party-zendesk {
  color: var(--ui-color-third-party-zendesk);
}

.ui-color-third-party-okta {
  color: var(--ui-color-third-party-okta);
}

.ui-color-third-party-auth-0 {
  color: var(--ui-color-third-party-auth-0);
}

.ui-color-third-party-paypal {
  color: var(--ui-color-third-party-paypal);
}

.ui-color-third-party-drive {
  color: var(--ui-color-third-party-drive);
}

.ui-color-third-party-linkedin {
  color: var(--ui-color-third-party-linkedin);
}

/// Media query for screen wider than extra small size.
/// @group media-query
/// @group theme
/// @output Style in media query for screen wider than extra small size
@mixin ui-media-xs {
  @media (min-width: 320px) {
    @content;
  }
}

/// Media query for screen wider than small size.
/// @group media-query
/// @group theme
/// @output Style in media query for screen wider than small size
@mixin ui-media-sm {
  @media (min-width: 600px) {
    @content;
  }
}

/// Media query for screen wider than medium size.
/// @group media-query
/// @group theme
/// @output Style in media query for screen wider than medium size
@mixin ui-media-md {
  @media (min-width: 768px) {
    @content;
  }
}

/// Media query for screen wider than large size.
/// @group media-query
/// @group theme
/// @output Style in media query for screen wider than large size
@mixin ui-media-lg {
  @media (min-width: 1024px) {
    @content;
  }
}

/// Media query for screen wider than extra large size.
/// @group media-query
/// @group theme
/// @output Style in media query for screen wider than large size
@mixin ui-media-xl {
  @media (min-width: 1920px) {
    @content;
  }
}

/// Media query that takes the size as a parameter
/// @group media-query
/// @group theme
/// @output Style in media query for selected size
@mixin ui-media($size: xs) {
  @if ($size == xs) {
    @include ui-media-xs {
      @content;
    }
  }

  @if ($size == sm) {
    @include ui-media-sm {
      @content;
    }
  }

  @if ($size == md) {
    @include ui-media-md {
      @content;
    }
  }

  @if ($size == lg) {
    @include ui-media-lg {
      @content;
    }
  }

  @if ($size == xl) {
    @include ui-media-xl {
      @content;
    }
  }
}

/// Media query for element wider than extra small size.
/// @group media-query
/// @group theme
/// @output Style in media query for element wider than extra small size
@mixin ui-media-element-xs {
  #{if(&, "&", "*")}.ui-media-xs,
  #{if(&, "&", "*")}.ui-media-sm,
  #{if(&, "&", "*")}.ui-media-md,
  #{if(&, "&", "*")}.ui-media-lg,
  #{if(&, "&", "*")}.ui-media-xl {
    @content;
  }
}

/// Media query for element wider than small size.
/// @group media-query
/// @group theme
/// @output Style in media query for element wider than small size
@mixin ui-media-element-sm {
  #{if(&, "&", "*")}.ui-media-sm,
  #{if(&, "&", "*")}.ui-media-md,
  #{if(&, "&", "*")}.ui-media-lg,
  #{if(&, "&", "*")}.ui-media-xl {
    @content;
  }
}

/// Media query for element wider than medium size.
/// @group media-query
/// @group theme
/// @output Style in media query for element wider than medium size
@mixin ui-media-element-md {
  #{if(&, "&", "*")}.ui-media-md,
  #{if(&, "&", "*")}.ui-media-lg,
  #{if(&, "&", "*")}.ui-media-xl {
    @content;
  }
}

/// Media query for element wider than large size.
/// @group media-query
/// @group theme
/// @output Style in media query for element wider than large size
@mixin ui-media-element-lg {
  #{if(&, "&", "*")}.ui-media-lg,
  #{if(&, "&", "*")}.ui-media-xl {
    @content;
  }
}

/// Media query for element wider than extra large size.
/// @group media-query
/// @group theme
/// @output Style in media query for element wider than large size
@mixin ui-media-element-xl {
  #{if(&, "&", "*")}.ui-media-xl {
    @content;
  }
}


$display-properties: (
  none,
  inline,
  inline-block,
  block,
  table,
  table-cell,
  table-row,
  flex,
  inline-flex
);

$breakpoints: (xs, sm, md, lg, xl);

@each $display-property in $display-properties {
  .ui-display-#{$display-property} {
    display: #{$display-property};
  }

  @each $breakpoint in $breakpoints {
    .ui-display-#{$display-property}-#{$breakpoint} {
      @include ui-media($breakpoint) {
        display: #{$display-property};
      }
    }
  }
}

.dcb-ui-hide-overflow {
  overflow: hidden;
}

dcb-ui-accordion,
ui-accordion,
dcb-ui-accordion-panel,
ui-accordion-panel,
dcb-ui-avatar,
ui-avatar,
dcb-ui-avatar-date,
dcb-ui-badge-alert,
ui-badge-alert,
dcb-ui-badge-icon,
ui-badge-icon,
dcb-ui-badge-status,
ui-badge-status,
dcb-ui-blank-slate,
ui-blank-slate,
dcb-ui-button-chip,
ui-button-chip,
dcb-ui-button-dropdown,
ui-button-dropdown,
dcb-ui-button-dropdown-option,
ui-button-dropdown-option,
dcb-ui-button-fab,
ui-button-fab,
dcb-ui-button-icon,
ui-button-icon,
dcb-ui-button-link,
ui-button-link,
dcb-ui-button-outlined,
ui-button-outlined,
dcb-ui-button-raised,
ui-button-raised,
dcb-ui-button-raised-neutral,
ui-button-raised-neutral,
dcb-ui-button-raised-primary,
ui-button-raised-primary,
dcb-ui-button-text,
ui-button-text,
dcb-ui-calendar-day,
dcb-ui-calendar-full,
dcb-ui-calendar-month,
dcb-ui-calendar-year,
dcb-ui-calendar-cell,
dcb-ui-calendar-header,
dcb-ui-card,
ui-card,
dcb-ui-card-content,
ui-card-content,
dcb-ui-card-footer,
ui-card-footer,
dcb-ui-card-header,
ui-card-header,
dcb-ui-card-catalog-content,
ui-card-catalog-content,
dcb-ui-card-docebo-content,
ui-card-docebo-content,
dcb-ui-card-external-content,
ui-card-external-content,
dcb-ui-card-formal-content,
ui-card-formal-content,
dcb-ui-card-informal-content,
ui-card-informal-content,
dcb-ui-card-topic,
ui-card-topic,
dcb-ui-cards-grid,
ui-cards-grid,
dcb-ui-cards-grid-slot,
ui-cards-grid-slot,
dcb-ui-carousel,
ui-carousel,
dcb-ui-chip,
ui-chip,
dcb-ui-chip-link,
dcb-ui-chip-action,
dcb-ui-chip-option-list,
dcb-ui-chip-option,
dcb-ui-chip-overflow,
dcb-ui-chip-simple,
dcb-ui-code-snippet,
ui-code-snippet,
dcb-ui-content-image,
ui-content-image,
dcb-ui-country-selector,
ui-country-selector,
dcb-ui-data-browser,
ui-data-browser,
dcb-ui-data-browser-control-dropdown,
ui-data-browser-control-dropdown,
dcb-ui-data-browser-control-search,
ui-data-browser-control-search,
dcb-ui-data-browser-panel-categories,
ui-data-browser-panel-categories,
dcb-ui-data-browser-panel-filters,
ui-data-browser-panel-filters,
dcb-ui-data-browser-filter,
ui-data-browser-filter,
dcb-ui-data-browser-filter-duration,
ui-data-browser-filter-duration,
dcb-ui-data-browser-filter-multiple-selection,
ui-data-browser-filter-multiple-selection,
dcb-ui-data-browser-filter-rating,
ui-data-browser-filter-rating,
dcb-ui-data-browser-filter-single-selection,
ui-data-browser-filter-single-selection,
dcb-ui-data-browser-filters-group,
ui-data-browser-filters-group,
dcb-ui-data-browser-content,
ui-data-browser-content,
dcb-ui-data-browser-control,
ui-data-browser-control,
dcb-ui-data-browser-panel,
ui-data-browser-panel,
dcb-ui-dialog,
ui-dialog,
dcb-ui-facet-list,
ui-facet-list,
dcb-ui-facet,
ui-facet,
dcb-ui-icon,
ui-icon,
dcb-ui-illustration,
ui-illustration,
dcb-ui-image-selector,
ui-image-selector,
dcb-ui-input-error,
ui-input-error,
dcb-ui-input-hint,
ui-input-hint,
dcb-ui-input-warning,
ui-input-warning,
dcb-ui-input-checkbox,
ui-input-checkbox,
dcb-ui-input-checkbox-group,
ui-input-checkbox-group,
dcb-ui-input-date,
ui-input-date,
dcb-ui-input-password,
ui-input-password,
dcb-ui-input-radio,
ui-input-radio,
dcb-ui-input-radiogroup,
ui-input-radiogroup,
dcb-ui-input-search,
ui-input-search,
dcb-ui-input-select,
ui-input-select,
dcb-ui-input-slider,
ui-input-slider,
dcb-ui-input-text,
ui-input-text,
dcb-ui-input-textarea,
ui-input-textarea,
dcb-ui-input-upload,
ui-input-upload,
dcb-ui-avatar-list-item,
ui-avatar-list-item,
dcb-ui-language-selector,
ui-language-selector,
dcb-ui-link,
ui-link,
dcb-ui-list,
dcb-ui-list-item,
ui-list-item,
dcb-ui-list-item-catalog-content,
ui-list-item-catalog-content,
dcb-ui-list-item-formal-content,
ui-list-item-formal-content,
ui-list-item-formal,
dcb-ui-list-item-informal-content,
ui-list-item-informal-content,
ui-list-item-informal,
dcb-ui-list-item-training-material-content,
ui-list-item-training-material-content,
dcb-ui-menu,
dcb-ui-message,
ui-message,
dcb-ui-notification-banner,
ui-notification-banner,
dcb-ui-notification-inline,
ui-notification-inline,
dcb-ui-notification-toast,
ui-notification-toast,
dcb-ui-notification-toast-error,
ui-notification-toast-error,
dcb-ui-notification-toast-neutral,
ui-notification-toast-neutral,
dcb-ui-notification-toast-success,
ui-notification-toast-success,
dcb-ui-panel-footer,
ui-panel-footer,
dcb-ui-paginator,
dcb-ui-paragraph,
ui-paragraph,
dcb-ui-player-video,
ui-player-video,
dcb-ui-player-vimeo,
ui-player-vimeo,
dcb-ui-player-wistia,
ui-player-wistia,
dcb-ui-player-youtube,
ui-player-youtube,
dcb-ui-pop-over,
dcb-ui-pop-over-content,
dcb-ui-progress-bar,
ui-progress-bar,
dcb-ui-rating-selector,
ui-rating-selector,
dcb-ui-rating-value,
ui-rating-value,
dcb-ui-spinner,
ui-spinner,
dcb-ui-stepper,
ui-stepper,
dcb-ui-step,
ui-step,
dcb-ui-summary,
ui-summary,
dcb-ui-tabs-horizontal,
dcb-ui-tab-horizontal,
ui-tab-horizontal,
dcb-ui-tabs-horizontal-header,
dcb-ui-tabs-vertical,
ui-tabs-vertical,
dcb-ui-tab-vertical,
ui-tab-vertical,
dcb-ui-tabs-vertical-group,
ui-tabs-vertical-group,
dcb-ui-table,
dcb-ui-thread,
dcb-ui-tree,
dcb-ui-input-toggle,
ui-tree,
dcb-ui-input-autocomplete,
dcb-ui-input-text-label,
.ui-tooltip,
.ui-tooltip-ghost {
  color: var(--ui-color-typography-primary);
  font-size: var(--ui-typography-size-sm);
  font-family: var(--ui-typography-family);
  line-height: var(--ui-typography-lineheight-md);
  font-weight: var(--ui-typography-weight-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    font-family: var(--ui-typography-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

/* @TODO: Import the browser resets for all the library components when we'll get the approval from FLOW */
dcb-ui-table {
  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html,
body {
  line-height: 1;
  height: auto !important;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

i {
  font-style: normal;
}

button {
  margin: 0;
}

}

@mixin ui-keyboard-navigation {
  .ui-keyboard-navigation & {
    @content;
  }
}

%ui-text-link {
  color: var(--ui-color-typography-accent);
  fill: var(--ui-color-typography-accent);
  font-weight: var(--ui-typography-weight-semibold);
  text-decoration: none;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }

  &:focus {
    text-decoration: underline;
  }

  @include ui-keyboard-navigation {
    &:focus {
      outline: solid 1px var(--ui-color-border-accessibility-default);
      outline-offset: var(--ui-spacing-micro);
    }
  }

  &:active {
    opacity: var(--ui-opacity-middle);
  }
}

%ui-text-link-negative {
  color: var(--ui-color-typography-negative);
  fill: var(--ui-color-typography-negative);
  font-weight: var(--ui-typography-weight-semibold);
  text-decoration: none;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }

  &:focus {
    text-decoration: underline;
  }

  @include ui-keyboard-navigation {
    &:focus {
      outline: solid 1px var(--ui-color-border-accessibility-negative);
      outline-offset: var(--ui-spacing-micro);
    }
  }

  &:active {
    background-color: var(--ui-color-interaction-hover-light);
  }
}

%ui-a11y-hover-idle {
  position: relative;

  @media (hover: hover) {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: var(--ui-opacity-transparent);
      will-change: opacity;
      background-color: var(--ui-color-interaction-hover-dark);
      transition: opacity var(--ui-motion-duration-short)
        var(--ui-motion-easing-entering);
    }
  }
}

%ui-a11y-hover-active {
  @media (hover: hover) {
    &:before {
      opacity: var(--ui-opacity-full);
    }
  }
}

%ui-a11y-outline {
  display: block;
  position: absolute;
  top: var(--ui-spacing-micro);
  left: var(--ui-spacing-micro);
  width: calc(100% - var(--ui-spacing-tiny));
  height: calc(100% - var(--ui-spacing-tiny));
  box-sizing: border-box;
  opacity: 0;
  border: solid var(--ui-size-border-xs)
    var(--ui-color-border-accessibility-default);
  border-radius: var(--ui-radius-default);
  will-change: opacity;
  transition: opacity var(--ui-motion-duration-short)
    var(--ui-motion-easing-entering);
}


.ui-text-link {
  @extend %ui-text-link;
}

.ui-text-link-negative {
  @extend %ui-text-link-negative;
}

[class*='ui-modal-'] {
  width: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.24);
  box-shadow: var(--ui-shadow-md) var(--ui-color-layout-shadow);
}

.ui-modal-sm {
  max-width: 28rem;
  max-width: var(--ui-size-modal-sm);
}

.ui-modal-md {
  max-width: 37.5rem;
  max-width: var(--ui-size-modal-md);
}

.ui-modal-lg {
  max-width: 50rem;
  max-width: var(--ui-size-modal-lg);
}

.ui-modal-full {
  max-width: 100%;
  max-width: var(--ui-size-modal-full);
}

.ui-shadow-md {
  box-shadow: var(--ui-shadow-md) var(--ui-color-layout-shadow);
}

.ui-shadow-lg {
  box-shadow: var(--ui-shadow-lg) var(--ui-color-layout-shadow);
}

[class*='ui-typography-heading-'] {
  font-weight: var(--ui-typography-weight-semibold);
  line-height: var(--ui-typography-lineheight-sm);
}

.ui-typography-heading-1 {
  font-size: var(--ui-typography-size-xxl);
}

.ui-typography-heading-2 {
  font-size: var(--ui-typography-size-xl);
}

.ui-typography-heading-3 {
  font-size: var(--ui-typography-size-lg);
}

.ui-typography-heading-4 {
  font-size: var(--ui-typography-size-md);
}

.ui-typography-heading-5 {
  font-size: var(--ui-typography-size-sm);
}

.ui-typography-subtitle {
  font-weight: var(--ui-typography-weight-semibold);
  font-size: var(--ui-typography-size-xs);
  line-height: var(--ui-typography-lineheight-sm);
}

.ui-typography-body {
  font-weight: var(--ui-typography-weight-regular);
  font-size: var(--ui-typography-size-sm);
  line-height: var(--ui-typography-lineheight-md);
}

.ui-typography-link-1 {
  font-weight: var(--ui-typography-weight-semibold);
  font-size: var(--ui-typography-size-sm);
  line-height: var(--ui-typography-lineheight-md);
}

.ui-typography-link-2 {
  font-weight: var(--ui-typography-weight-semibold);
  font-size: var(--ui-typography-size-xs);
  line-height: var(--ui-typography-lineheight-sm);
}

.ui-typography-button {
  font-weight: var(--ui-typography-weight-bold);
  font-size: var(--ui-typography-size-sm);
  line-height: var(--ui-typography-lineheight-sm);
}

.ui-typography-caption-1 {
  font-weight: var(--ui-typography-weight-bold);
  font-size: var(--ui-typography-size-xs);
  line-height: var(--ui-typography-lineheight-sm);
}

.ui-typography-caption-2 {
  font-weight: var(--ui-typography-weight-bold);
  font-size: var(--ui-typography-size-xxs);
  line-height: var(--ui-typography-lineheight-sm);
}

.ui-typography-uppercase {
  text-transform: uppercase;
}


/**
 * Do not edit directly
 * Generated on Wed, 27 Apr 2022 15:22:57 GMT
 */

:root {
  --ui-button-icon-cursor: pointer;
  --ui-color-layout-background: rgba(
    245,
    245,
    245,
    1
  ); /* widget based dashboards background */
  --ui-color-layout-surface: rgba(
    255,
    255,
    255,
    1
  ); /* primary surfaces of the product */
  --ui-color-layout-brand: rgba(
    4,
    101,
    172,
    1
  ); /* layout areas that inherit the brand color */
  --ui-color-layout-prominence: rgba(
    228,
    230,
    229,
    1
  ); /* areas that need to be emphasized like headers */
  --ui-color-layout-placeholder: rgba(
    245,
    245,
    245,
    1
  ); /* objects placeholder color */
  --ui-color-layout-contrast: rgba(
    30,
    32,
    40,
    1
  ); /* high contrast layout areas */
  --ui-color-layout-shadow: rgba(0, 0, 0, 0.24);
  --ui-color-layout-overlay-dark: rgba(
    0,
    0,
    0,
    1
  ); /* used for screen overlay - Apply an opacity overlay */
  --ui-color-layout-overlay-light: rgba(255, 255, 255, 1);
  --ui-color-accent-primary: rgba(4, 101, 172, 1); /* used for branding */
  --ui-color-accent-secondary: rgba(
    4,
    101,
    172,
    1
  ); /* used for interactive elements */
  --ui-color-accent-tertiary: rgba(0, 135, 103, 1); /* used for buttons */
  --ui-color-illustration-primary: rgba(4, 101, 172, 1);
  --ui-color-typography-primary: rgba(33, 39, 33, 1);
  --ui-color-typography-secondary: rgba(102, 102, 102, 1);
  --ui-color-typography-accent: rgba(4, 101, 172, 1); /* used for links */
  --ui-color-typography-negative: rgba(255, 255, 255, 1);
  --ui-color-typography-error: rgba(207, 0, 15, 1);
  --ui-color-typography-errorreverse: rgba(255, 147, 147, 1);
  --ui-color-typography-success: rgba(0, 135, 103, 1);
  --ui-color-typography-alert: rgba(247, 104, 30, 1);
  --ui-color-semantic-success: rgba(0, 135, 103, 1);
  --ui-color-semantic-error-default: rgba(207, 0, 15, 1);
  --ui-color-semantic-error-reverse: rgba(255, 147, 147, 1);
  --ui-color-semantic-alert: rgba(247, 104, 30, 1);
  --ui-color-border-accessibility-default: rgba(33, 39, 33, 1);
  --ui-color-border-accessibility-negative: rgba(255, 255, 255, 1);
  --ui-color-border-accent-secondary: rgba(
    4,
    101,
    172,
    1
  ); /* active inputs and loading */
  --ui-color-border-accent-tertiary: rgba(0, 135, 103, 1);
  --ui-color-border-neutral: rgba(228, 230, 229, 1);
  --ui-color-border-middle: rgba(102, 102, 102, 1);
  --ui-color-border-baseline: rgba(228, 230, 229, 1);
  --ui-color-border-negative: rgba(255, 255, 255, 1);
  --ui-color-border-dark: rgba(51, 51, 51, 1);
  --ui-color-border-error: rgba(207, 0, 15, 1);
  --ui-color-border-errorreverse: rgba(255, 147, 147, 1);
  --ui-color-border-alert: rgba(247, 104, 30, 1);
  --ui-color-border-success: rgba(0, 135, 103, 1);
  --ui-color-icon-accent-primary: rgba(4, 101, 172, 1);
  --ui-color-icon-accent-secondary: rgba(4, 101, 172, 1);
  --ui-color-icon-accent-tertiary: rgba(0, 135, 103, 1);
  --ui-color-icon-dark: rgba(51, 51, 51, 1);
  --ui-color-icon-neutral: rgba(102, 102, 102, 1);
  --ui-color-icon-negative: rgba(255, 255, 255, 1);
  --ui-color-icon-alert: rgba(247, 104, 30, 1);
  --ui-color-icon-error: rgba(207, 0, 15, 1);
  --ui-color-icon-success: rgba(0, 135, 103, 1);
  --ui-color-icon-rating: rgba(4, 101, 172, 1);
  --ui-color-avatar-background-negative: rgba(255, 255, 255, 1);
  --ui-color-avatar-background-superlight: rgba(245, 245, 245, 1);
  --ui-color-avatar-background-dark: rgba(51, 51, 51, 1);
  --ui-color-avatar-background-light: rgba(228, 230, 229, 1);
  --ui-color-avatar-background-neutral: rgba(102, 102, 102, 1);
  --ui-color-avatar-background-accent: rgba(4, 101, 172, 1);
  --ui-color-avatar-background-error: rgba(207, 0, 15, 1);
  --ui-color-avatar-background-alert: rgba(247, 104, 30, 1);
  --ui-color-avatar-background-success: rgba(0, 135, 103, 1);
  --ui-color-avatar-label-dark: rgba(33, 39, 33, 1);
  --ui-color-avatar-label-middle: rgba(102, 102, 102, 1);
  --ui-color-avatar-label-negative: rgba(255, 255, 255, 1);
  --ui-color-avatar-label-accent: rgba(4, 101, 172, 1);
  --ui-color-header-background-default: rgba(
    255,
    255,
    255,
    1
  ); /* background in the header of the app */
  --ui-color-header-background-accent: rgba(
    4,
    101,
    172,
    1
  ); /* used for the drawer */
  --ui-color-header-accent: rgba(
    4,
    101,
    172,
    1
  ); /* used for the icons in the header of the app */
  --ui-color-header-typography-default: rgba(
    33,
    39,
    33,
    1
  ); /* used for the page title in the header of the app */
  --ui-color-bread-background: rgba(4, 101, 172, 1);
  --ui-color-bread-label-dark: rgba(
    51,
    51,
    51,
    1
  ); /* when background color is light */
  --ui-color-bread-label-negative: rgba(
    255,
    255,
    255,
    1
  ); /* when background color is dark */
  --ui-color-chip-background-light: rgba(228, 230, 229, 1);
  --ui-color-chip-background-accent: rgba(4, 101, 172, 1);
  --ui-color-chip-label-dark: rgba(33, 39, 33, 1);
  --ui-color-chip-label-medium: rgba(102, 102, 102, 1);
  --ui-color-chip-label-light: rgba(255, 255, 255, 1);
  --ui-color-chip-border-accent-secondary: rgba(4, 101, 172, 1);
  --ui-color-chip-border-light: rgba(228, 230, 229, 1);
  --ui-color-chip-border-medium: rgba(102, 102, 102, 1);
  --ui-color-badge-background-success: rgba(0, 135, 103, 1);
  --ui-color-badge-background-dark: rgba(51, 51, 51, 1);
  --ui-color-badge-background-alert: rgba(247, 104, 30, 1);
  --ui-color-badge-background-error: rgba(207, 0, 15, 1);
  --ui-color-badge-background-light: rgba(228, 230, 229, 1);
  --ui-color-badge-background-accent: rgba(4, 101, 172, 1);
  --ui-color-badge-label-dark: rgba(51, 51, 51, 1);
  --ui-color-badge-label-light: rgba(255, 255, 255, 1);
  --ui-color-badge-icon-dark: rgba(51, 51, 51, 1);
  --ui-color-badge-icon-light: rgba(255, 255, 255, 1);
  --ui-color-button-background-primary: rgba(
    0,
    135,
    103,
    1
  ); /* primary contained button background */
  --ui-color-button-background-secondary: rgba(
    255,
    255,
    255,
    1
  ); /* secondary contained button background */
  --ui-color-button-border-primary: rgba(
    0,
    135,
    103,
    1
  ); /* border of the outline button */
  --ui-color-button-border-negative: rgba(
    255,
    255,
    255,
    1
  ); /* border of the outline negative button */
  --ui-color-button-border-accent-secondary: rgba(
    4,
    101,
    172,
    1
  ); /* to be deprecated */
  --ui-color-button-border-tabs: rgba(
    4,
    101,
    172,
    1
  ); /* bottom border of the active tab */
  --ui-color-button-label-primary: rgba(
    255,
    255,
    255,
    1
  ); /* label of the primary contained button */
  --ui-color-button-label-accent: rgba(
    0,
    135,
    103,
    1
  ); /* label of the primary flat button */
  --ui-color-button-label-accent-secondary: rgba(
    4,
    101,
    172,
    1
  ); /* used for active states on neutral buttons */
  --ui-color-button-label-tabs: rgba(
    4,
    101,
    172,
    1
  ); /* label of the active tab */
  --ui-color-button-label-dark: rgba(
    33,
    39,
    33,
    1
  ); /* used in some cases when high contrast is needed */
  --ui-color-button-label-neutral: rgba(
    102,
    102,
    102,
    1
  ); /* label of the secondary text button and of the inactive tabs */
  --ui-color-button-label-negative: rgba(255, 255, 255, 1);
  --ui-color-button-label-error: rgba(207, 0, 15, 1);
  --ui-color-dialog-background-dark: rgba(
    51,
    51,
    51,
    1
  ); /* used for tooltips */
  --ui-color-dialog-background-neutral: rgba(
    228,
    230,
    229,
    1
  ); /* used for neutral banners */
  --ui-color-dialog-background-soft: rgba(
    245,
    245,
    245,
    1
  ); /* used for info and tooltips */
  --ui-color-dialog-background-light: rgba(
    255,
    255,
    255,
    1
  ); /* used for baloons */
  --ui-color-dialog-background-accent: rgba(4, 101, 172, 1);
  --ui-color-dialog-background-success: rgba(0, 135, 103, 1);
  --ui-color-dialog-background-alert: rgba(247, 104, 30, 1);
  --ui-color-dialog-background-error: rgba(207, 0, 15, 1);
  --ui-color-dialog-label-dark: rgba(33, 39, 33, 1);
  --ui-color-dialog-label-negative: rgba(255, 255, 255, 1);
  --ui-color-choice-background-active: rgba(0, 135, 103, 1);
  --ui-color-choice-background-inactive: rgba(102, 102, 102, 1);
  --ui-color-choice-knob: rgba(255, 255, 255, 1);
  --ui-color-third-party-google: rgba(219, 68, 55, 1);
  --ui-color-third-party-facebook: rgba(60, 90, 153, 1);
  --ui-color-third-party-twitter: rgba(29, 161, 242, 1);
  --ui-color-third-party-zendesk: rgba(120, 163, 0, 1);
  --ui-color-third-party-okta: rgba(0, 156, 221, 1);
  --ui-color-third-party-auth-0: rgba(235, 83, 35, 1);
  --ui-color-third-party-paypal: rgba(0, 48, 135, 1);
  --ui-color-third-party-drive: rgba(0, 135, 68, 1);
  --ui-color-third-party-linkedin: rgba(0, 119, 181, 1);
  --ui-color-third-party-openid: rgba(255, 98, 2, 1);
  --ui-color-third-party-ceridian: rgba(48, 103, 219, 1);
  --ui-color-loading-accent: rgba(4, 101, 172, 1);
  --ui-color-loading-neutral: rgba(102, 102, 102, 1);
  --ui-color-loading-light: rgba(228, 230, 229, 1);
  --ui-color-loading-negative: rgba(255, 255, 255, 1);
  --ui-color-loading-error: rgba(207, 0, 15, 1);
  --ui-color-interaction-hover-dark: rgba(0, 0, 0, 0.08);
  --ui-color-interaction-hover-light: rgba(255, 255, 255, 0.24);
  --ui-color-interaction-hover-accent-secondary: rgba(4, 101, 172, 0.08);
  --ui-color-interaction-hover-accent-tertiary: rgba(0, 135, 103, 0.08);
  --ui-color-interaction-ripple-dark: rgba(0, 0, 0, 0.08);
  --ui-color-interaction-ripple-light: rgba(255, 255, 255, 0.24);
  --ui-color-interaction-ripple-accent-secondary: rgba(4, 101, 172, 0.08);
  --ui-color-interaction-ripple-accent-tertiary: rgba(0, 135, 103, 0.08);
  --ui-color-interaction-active-dark: rgba(0, 0, 0, 0.08);
  --ui-color-interaction-active-light: rgba(255, 255, 255, 0.24);
  --ui-color-interaction-active-accent-secondary: rgba(4, 101, 172, 0.08);
  --ui-color-interaction-active-accent-tertiary: rgba(0, 135, 103, 0.08);
  --ui-color-charts-background-light: rgba(
    228,
    230,
    229,
    1
  ); /* use for no value charts areas */
  --ui-color-charts-background-neutral: rgba(
    102,
    102,
    102,
    1
  ); /* use for indeterminate values */
  --ui-color-charts-background-alert: rgba(247, 104, 30, 1);
  --ui-color-charts-background-error: rgba(207, 0, 15, 1);
  --ui-color-charts-background-success: rgba(0, 135, 103, 1);
  --ui-layout-grid-columns-xs: 4;
  --ui-layout-grid-columns-sm: 4;
  --ui-layout-grid-columns-md: 8;
  --ui-layout-grid-columns-lg: 12;
  --ui-layout-grid-columns-xl: 12;
  --ui-layout-grid-gutter-xs: calc(
    1 * var(--ui-typography-size-base)
  ); /* 16px */
  --ui-layout-grid-gutter-sm: calc(
    1 * var(--ui-typography-size-base)
  ); /* 16px */
  --ui-layout-grid-gutter-md: calc(
    1 * var(--ui-typography-size-base)
  ); /* 16px */
  --ui-layout-grid-gutter-lg: calc(
    1.5 * var(--ui-typography-size-base)
  ); /* 24px */
  --ui-layout-grid-gutter-xl: calc(
    1.5 * var(--ui-typography-size-base)
  ); /* 24px */
  --ui-layout-grid-margin-xs: calc(
    1 * var(--ui-typography-size-base)
  ); /* 16px */
  --ui-layout-grid-margin-sm: calc(
    1 * var(--ui-typography-size-base)
  ); /* 16px */
  --ui-layout-grid-margin-md: calc(
    1 * var(--ui-typography-size-base)
  ); /* 16px */
  --ui-layout-grid-margin-lg: calc(
    1.5 * var(--ui-typography-size-base)
  ); /* 24px */
  --ui-layout-grid-margin-xl: calc(
    1.5 * var(--ui-typography-size-base)
  ); /* 24px */
  --ui-layout-grid-max-width-md-narrow: calc(
    60 * var(--ui-typography-size-base)
  ); /* use it when the content of the page is mainly text and you need the page to be readable easily */
  --ui-layout-grid-max-width-lg-narrow: calc(
    60 * var(--ui-typography-size-base)
  ); /* use it when the content of the page is mainly text and you need the page to be readable easily */
  --ui-layout-grid-max-width-lg-slender: calc(
    78.75 * var(--ui-typography-size-base)
  ); /* use it when there are a couple of colums and you want to keep content readable */
  --ui-layout-grid-max-width-lg-wide: calc(
    90 * var(--ui-typography-size-base)
  ); /* use it for dashboards with a lot of objects and you want users to browse */
  --ui-layout-grid-max-width-xl-narrow: calc(
    60 * var(--ui-typography-size-base)
  ); /* use it when the content of the page is mainly text and you need the page to be readable easily */
  --ui-layout-grid-max-width-xl-slender: calc(
    78.75 * var(--ui-typography-size-base)
  ); /* use it when there are a couple of colums and you want to keep content readable */
  --ui-layout-grid-max-width-xl-wide: calc(
    90 * var(--ui-typography-size-base)
  ); /* use it for dashboards with a lot of objects and you want users to browse */
  --ui-layout-grid-width-full: 100%; /* content takes the entire horizontal space */
  --ui-motion-duration-micro: 100ms;
  --ui-motion-duration-short: 200ms;
  --ui-motion-duration-normal: 300ms;
  --ui-motion-duration-long: 700ms;
  --ui-motion-easing-default: linear;
  --ui-motion-easing-leaving: ease-in;
  --ui-motion-easing-entering: ease-out;
  --ui-opacity-full: 1;
  --ui-opacity-high: 0.8;
  --ui-opacity-middle: 0.5;
  --ui-opacity-low: 0.24;
  --ui-opacity-tiny: 0.16;
  --ui-opacity-micro: 0.08;
  --ui-opacity-nano: 0.04;
  --ui-opacity-transparent: 0;
  --ui-radius-default: calc(0.125 * var(--ui-typography-size-base)); /* 2px */
  --ui-radius-comment: calc(0.75 * var(--ui-typography-size-base)); /* 12px */
  --ui-radius-circular: 50%;
  --ui-radius-pill: 50vh;
  --ui-shadow-md: 0 1px 4px 0;
  --ui-shadow-lg: 0 4px 8px 0;
  --ui-size-border-xs: calc(0.0625 * var(--ui-typography-size-base)); /* 1px */
  --ui-size-border-sm: calc(0.125 * var(--ui-typography-size-base)); /* 2px */
  --ui-size-border-md: calc(0.25 * var(--ui-typography-size-base)); /* 4px */
  --ui-size-icon-micro: calc(0.25 * var(--ui-typography-size-base)); /* 4px */
  --ui-size-icon-tiny: calc(0.5 * var(--ui-typography-size-base)); /* 8px */
  --ui-size-icon-xxs: calc(0.75 * var(--ui-typography-size-base)); /* 12px */
  --ui-size-icon-xs: calc(1 * var(--ui-typography-size-base)); /* 16px */
  --ui-size-icon-sm: calc(1.25 * var(--ui-typography-size-base)); /* 20px */
  --ui-size-icon-md: calc(1.5 * var(--ui-typography-size-base)); /* 24px */
  --ui-size-icon-lg: calc(2 * var(--ui-typography-size-base)); /* 32px */
  --ui-size-icon-xl: calc(2.5 * var(--ui-typography-size-base)); /* 40px */
  --ui-size-icon-xxl: calc(3.5 * var(--ui-typography-size-base)); /* 56px */
  --ui-size-icon-xxxl: calc(4.5 * var(--ui-typography-size-base)); /* 72px */
  --ui-size-contentimage-full: 100%;
  --ui-size-contentimage-xxs: calc(
    3.5 * var(--ui-typography-size-base)
  ); /* 56px */
  --ui-size-contentimage-xs: calc(
    7 * var(--ui-typography-size-base)
  ); /* 112px */
  --ui-size-contentimage-sm: calc(
    8.25 * var(--ui-typography-size-base)
  ); /* 132px */
  --ui-size-contentimage-md: calc(
    11.75 * var(--ui-typography-size-base)
  ); /* 188px */
  --ui-size-contentimage-lg: calc(
    18.75 * var(--ui-typography-size-base)
  ); /* 300px */
  --ui-size-avatar-xs: calc(1.5 * var(--ui-typography-size-base)); /* 24px */
  --ui-size-avatar-sm: calc(2 * var(--ui-typography-size-base)); /* 32px */
  --ui-size-avatar-md: calc(2.5 * var(--ui-typography-size-base)); /* 40px */
  --ui-size-avatar-lg: calc(3 * var(--ui-typography-size-base)); /* 48px */
  --ui-size-avatar-xl: calc(3.5 * var(--ui-typography-size-base)); /* 56px */
  --ui-size-avatar-xxl: calc(4.5 * var(--ui-typography-size-base)); /* 72px */
  --ui-size-avatar-jumbo: calc(7 * var(--ui-typography-size-base)); /* 112px */
  --ui-size-choice-radio: calc(
    1.25 * var(--ui-typography-size-base)
  ); /* 20px */
  --ui-size-choice-checkbox: calc(
    1.125 * var(--ui-typography-size-base)
  ); /* 18px */
  --ui-size-menu-minwidth: calc(
    12.5 * var(--ui-typography-size-base)
  ); /* 200px */
  --ui-size-toggle-width: calc(
    2.75 * var(--ui-typography-size-base)
  ); /* 44px */
  --ui-size-toggle-height: calc(
    1.5 * var(--ui-typography-size-base)
  ); /* 24px */
  --ui-size-toggle-knob-width: calc(
    1.25 * var(--ui-typography-size-base)
  ); /* 20px */
  --ui-size-toggle-knob-height: calc(
    1.25 * var(--ui-typography-size-base)
  ); /* 20px */
  --ui-size-modal-full: 100%;
  --ui-size-modal-xxs: calc(14 * var(--ui-typography-size-base)); /* 224px */
  --ui-size-modal-xs: calc(20.75 * var(--ui-typography-size-base)); /* 332px */
  --ui-size-modal-sm: calc(28 * var(--ui-typography-size-base)); /* 448px */
  --ui-size-modal-md: calc(37.5 * var(--ui-typography-size-base)); /* 600px */
  --ui-size-modal-lg: calc(50 * var(--ui-typography-size-base)); /* 800px */
  --ui-size-rightpanel-md: calc(
    31.25 * var(--ui-typography-size-base)
  ); /* 500px */
  --ui-size-rightpanel-lg: calc(
    56.25 * var(--ui-typography-size-base)
  ); /* 900px */
  --ui-size-toast-minwidth: calc(
    18.75 * var(--ui-typography-size-base)
  ); /* 300px */
  --ui-size-toast-maxwidth: calc(
    30 * var(--ui-typography-size-base)
  ); /* 480px */
  --ui-size-badge-numeric-minwidth: calc(
    1 * var(--ui-typography-size-base)
  ); /* 16px */
  --ui-size-header-height: calc(
    3.25 * var(--ui-typography-size-base)
  ); /* 52px */
  --ui-size-handle-sm: calc(1 * var(--ui-typography-size-base)); /* 16px */
  --ui-size-handle-md: calc(1.25 * var(--ui-typography-size-base)); /* 20px */
  --ui-size-breadcrumb-height: calc(
    2 * var(--ui-typography-size-base)
  ); /* 32px */
  --ui-size-logo-sm-maxwidth: calc(11.875 * var(--ui-typography-size-base));
  --ui-size-logo-sm-maxheight: calc(2 * var(--ui-typography-size-base));
  --ui-size-illustration-full: 100%; /* padding contained image */
  --ui-size-illustration-xxs: calc(3 * var(--ui-typography-size-base));
  --ui-size-illustration-xs: calc(4.5 * var(--ui-typography-size-base));
  --ui-size-illustration-sm: calc(6.5 * var(--ui-typography-size-base));
  --ui-size-illustration-md: calc(12.75 * var(--ui-typography-size-base));
  --ui-size-illustration-lg: calc(18.5 * var(--ui-typography-size-base));
  --ui-size-illustration-xl: calc(28 * var(--ui-typography-size-base));
  --ui-size-list-sm-avatar-minheight: calc(3 * var(--ui-typography-size-base));
  --ui-size-list-sm-text-minheight: calc(2.75 * var(--ui-typography-size-base));
  --ui-size-list-md-avatar-minheight: calc(
    4.5 * var(--ui-typography-size-base)
  );
  --ui-size-list-md-text-minheight: calc(3.25 * var(--ui-typography-size-base));
  --ui-size-table-row-height-sm: calc(
    2.5 * var(--ui-typography-size-base)
  ); /* 40px */
  --ui-size-table-row-height-md: calc(
    3 * var(--ui-typography-size-base)
  ); /* 48px */
  --ui-size-table-row-height-lg: calc(
    3.5 * var(--ui-typography-size-base)
  ); /* 56px */
  --ui-size-table-column-fixed-max: calc(
    12.5 * var(--ui-typography-size-base)
  ); /* used for freezed columns max width */
  --ui-size-table-column-fixed-min: calc(
    3.5 * var(--ui-typography-size-base)
  ); /* used for freezed columns min width */
  --ui-spacing-micro: calc(0.125 * var(--ui-typography-size-base)); /* 2px */
  --ui-spacing-tiny: calc(0.25 * var(--ui-typography-size-base)); /* 4px */
  --ui-spacing-xxs: calc(0.5 * var(--ui-typography-size-base)); /* 8px */
  --ui-spacing-xs: calc(0.75 * var(--ui-typography-size-base)); /* 12px */
  --ui-spacing-sm: calc(1 * var(--ui-typography-size-base)); /* 16px */
  --ui-spacing-md: calc(1.5 * var(--ui-typography-size-base)); /* 24px */
  --ui-spacing-lg: calc(2 * var(--ui-typography-size-base)); /* 32px */
  --ui-spacing-xl: calc(2.5 * var(--ui-typography-size-base)); /* 40px */
  --ui-spacing-xxl: calc(3 * var(--ui-typography-size-base)); /* 48px */
  --ui-spacing-big: calc(3.5 * var(--ui-typography-size-base)); /* 56px */
  --ui-spacing-huge: calc(4.5 * var(--ui-typography-size-base)); /* 72px */
  --ui-typography-family: 'Open Sans', sans-serif;
  --ui-typography-size-base: 16px; /* Base font-size */
  --ui-typography-size-xxl: calc(
    1.875 * var(--ui-typography-size-base)
  ); /* 30px */
  --ui-typography-size-xl: calc(
    1.5 * var(--ui-typography-size-base)
  ); /* 24px */
  --ui-typography-size-lg: calc(
    1.125 * var(--ui-typography-size-base)
  ); /* 18px */
  --ui-typography-size-md: calc(1 * var(--ui-typography-size-base)); /* 16px */
  --ui-typography-size-sm: calc(
    0.875 * var(--ui-typography-size-base)
  ); /* 14px */
  --ui-typography-size-xs: calc(
    0.75 * var(--ui-typography-size-base)
  ); /* 12px */
  --ui-typography-size-xxs: calc(
    0.5 * var(--ui-typography-size-base)
  ); /* 8px */
  --ui-typography-weight-bold: 700;
  --ui-typography-weight-semibold: 600;
  --ui-typography-weight-regular: 400;
  --ui-typography-lineheight-sm: 1.25em;
  --ui-typography-lineheight-md: 1.5em;
  --ui-typography-lineheight-lg: 2em;
  --ui-zindex-default: 1;
  --ui-zindex-sticky: 100;
  --ui-zindex-overlay: 800;
  --ui-zindex-modal: 825;
  --ui-zindex-onthetop: 900;
  --ui-zindex-olympo: 2147483647;
}


// stylelint-disable order/order
/// Media query for screen wider than extra small size.
/// @group media-query
/// @group theme
/// @output Style in media query for screen wider than extra small size
@mixin ui-media-xs {
  @media (min-width: 320px) {
    @content;
  }
}

/// Media query for screen wider than small size.
/// @group media-query
/// @group theme
/// @output Style in media query for screen wider than small size
@mixin ui-media-sm {
  @media (min-width: 600px) {
    @content;
  }
}

/// Media query for screen wider than medium size.
/// @group media-query
/// @group theme
/// @output Style in media query for screen wider than medium size
@mixin ui-media-md {
  @media (min-width: 768px) {
    @content;
  }
}

/// Media query for screen wider than large size.
/// @group media-query
/// @group theme
/// @output Style in media query for screen wider than large size
@mixin ui-media-lg {
  @media (min-width: 1024px) {
    @content;
  }
}

/// Media query for screen wider than extra large size.
/// @group media-query
/// @group theme
/// @output Style in media query for screen wider than large size
@mixin ui-media-xl {
  @media (min-width: 1920px) {
    @content;
  }
}

/// Media query that takes the size as a parameter
/// @group media-query
/// @group theme
/// @output Style in media query for selected size
@mixin ui-media($size: xs) {
  @if ($size == xs) {
    @include ui-media-xs {
      @content;
    }
  }

  @if ($size == sm) {
    @include ui-media-sm {
      @content;
    }
  }

  @if ($size == md) {
    @include ui-media-md {
      @content;
    }
  }

  @if ($size == lg) {
    @include ui-media-lg {
      @content;
    }
  }

  @if ($size == xl) {
    @include ui-media-xl {
      @content;
    }
  }
}

/// Media query for element wider than extra small size.
/// @group media-query
/// @group theme
/// @output Style in media query for element wider than extra small size
@mixin ui-media-element-xs {
  #{if(&, "&", "*")}.ui-media-xs,
  #{if(&, "&", "*")}.ui-media-sm,
  #{if(&, "&", "*")}.ui-media-md,
  #{if(&, "&", "*")}.ui-media-lg,
  #{if(&, "&", "*")}.ui-media-xl {
    @content;
  }
}

/// Media query for element wider than small size.
/// @group media-query
/// @group theme
/// @output Style in media query for element wider than small size
@mixin ui-media-element-sm {
  #{if(&, "&", "*")}.ui-media-sm,
  #{if(&, "&", "*")}.ui-media-md,
  #{if(&, "&", "*")}.ui-media-lg,
  #{if(&, "&", "*")}.ui-media-xl {
    @content;
  }
}

/// Media query for element wider than medium size.
/// @group media-query
/// @group theme
/// @output Style in media query for element wider than medium size
@mixin ui-media-element-md {
  #{if(&, "&", "*")}.ui-media-md,
  #{if(&, "&", "*")}.ui-media-lg,
  #{if(&, "&", "*")}.ui-media-xl {
    @content;
  }
}

/// Media query for element wider than large size.
/// @group media-query
/// @group theme
/// @output Style in media query for element wider than large size
@mixin ui-media-element-lg {
  #{if(&, "&", "*")}.ui-media-lg,
  #{if(&, "&", "*")}.ui-media-xl {
    @content;
  }
}

/// Media query for element wider than extra large size.
/// @group media-query
/// @group theme
/// @output Style in media query for element wider than large size
@mixin ui-media-element-xl {
  #{if(&, "&", "*")}.ui-media-xl {
    @content;
  }
}

/// Apply content style to rtl direction.
@mixin rtl {
  [dir='rtl'] & {
    @content;
  }
}

// The same as @mixin rtl, but for encapsulated components
@mixin rtl-host {
  :host-context([dir='rtl']) & {
    @content;
  }
}


$cols: 24;

/**
Generic definition for rows.
Rows are the main entity for this grid, and can be used with 3 different presets: "wide"/"full", "slender" and "narrow".
The only difference among those presets lies in their max-width.
Default preset for .ui-row class is "wide"/"full". Other presets are defined below.

----------
USAGE EXAMPLE:

<section class="ui-row"></section>

Outcome:
- XS resolution: "full"
- SM resolution: "full"
- MD resolution: "full"
- LG resolution: "wide"
- XL resolution: "wide"
----------
 */
[class*='ui-row'] {
  margin: 0 auto;
  flex-grow: 1;
  box-sizing: border-box;

  @include ui-media-xs {
    max-width: var(--ui-layout-grid-width-full);
    padding: 0 calc(var(--ui-layout-grid-margin-xs) / 2);
  }

  @include ui-media-element-xs {
    max-width: var(--ui-layout-grid-width-full);
    padding: 0 calc(var(--ui-layout-grid-margin-xs) / 2);
  }

  @include ui-media-sm {
    max-width: var(--ui-layout-grid-width-full);
    padding: 0 calc(var(--ui-layout-grid-margin-sm) / 2);
  }

  @include ui-media-element-sm {
    max-width: var(--ui-layout-grid-width-full);
    padding: 0 calc(var(--ui-layout-grid-margin-sm) / 2);
  }

  @include ui-media-md {
    max-width: var(--ui-layout-grid-width-full);
    padding: 0 calc(var(--ui-layout-grid-margin-md) / 2);
  }

  @include ui-media-element-md {
    max-width: var(--ui-layout-grid-width-full);
    padding: 0 calc(var(--ui-layout-grid-margin-md) / 2);
  }

  @include ui-media-lg {
    max-width: var(--ui-layout-grid-max-width-lg-wide);
    padding: 0 calc(var(--ui-layout-grid-margin-lg) / 2);
  }

  @include ui-media-element-lg {
    max-width: var(--ui-layout-grid-max-width-lg-wide);
    padding: 0 calc(var(--ui-layout-grid-margin-lg) / 2);
  }

  @include ui-media-xl {
    max-width: var(--ui-layout-grid-max-width-xl-wide);
    padding: 0 calc(var(--ui-layout-grid-margin-xl) / 2);
  }

  @include ui-media-element-xl {
    max-width: var(--ui-layout-grid-max-width-xl-wide);
    padding: 0 calc(var(--ui-layout-grid-margin-xl) / 2);
  }

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  /**
  Definition for "slender" rows.
  Slender rows are only available on LG and XL resolutions.
  Other resolutions automatically fall back to "wide"/"full" preset.

  ----------
  USAGE EXAMPLE:

  <section class="ui-row-slender"></section>

  Outcome:
  - XS resolution: "full"
  - SM resolution: "full"
  - MD resolution: "full"
  - LG resolution: "slender"
  - XL resolution: "slender"
  ----------
   */
  &.ui-row-slender {
    @include ui-media-lg {
      max-width: var(--ui-layout-grid-max-width-lg-slender);
    }

    @include ui-media-element-lg {
      max-width: var(--ui-layout-grid-max-width-lg-slender);
    }

    @include ui-media-xl {
      max-width: var(--ui-layout-grid-max-width-xl-slender);
    }

    @include ui-media-element-xl {
      max-width: var(--ui-layout-grid-max-width-xl-slender);
    }
  }

  /**
  Definition for "narrow" rows.
  Narrow rows are only available on MD, LG and XL resolutions.
  Other resolutions automatically fall back to "wide"/"full" preset.

  ----------
  USAGE EXAMPLE:

  <section class="ui-row-narrow"></section>

  Outcome:
  - XS resolution: "full"
  - SM resolution: "full"
  - MD resolution: "narrow"
  - LG resolution: "narrow"
  - XL resolution: "narrow"
  ----------
   */
  &.ui-row-narrow {
    @include ui-media-md {
      max-width: var(--ui-layout-grid-max-width-md-narrow);
    }

    @include ui-media-element-md {
      max-width: var(--ui-layout-grid-max-width-md-narrow);
    }

    @include ui-media-lg {
      max-width: var(--ui-layout-grid-max-width-lg-narrow);
    }

    @include ui-media-element-lg {
      max-width: var(--ui-layout-grid-max-width-lg-narrow);
    }

    @include ui-media-xl {
      max-width: var(--ui-layout-grid-max-width-xl-narrow);
    }

    @include ui-media-element-xl {
      max-width: var(--ui-layout-grid-max-width-xl-narrow);
    }
  }

  /**
  Resolution-specific definitions for "narrow" and "slender" rows.

  ----------
  USAGE EXAMPLE:

  <section class="ui-row ui-row-xl-slender ui-row-lg-narrow"></section>

  Outcome:
  - XS resolution: "full"
  - SM resolution: "full"
  - MD resolution: "full"
  - LG resolution: "narrow"
  - XL resolution: "slender"
  ----------
   */
  &.ui-row-xl-slender {
    @include ui-media-xl {
      max-width: var(--ui-layout-grid-max-width-xl-slender);
    }

    @include ui-media-element-xl {
      max-width: var(--ui-layout-grid-max-width-xl-slender);
    }
  }

  &.ui-row-xl-narrow {
    @include ui-media-xl {
      max-width: var(--ui-layout-grid-max-width-xl-narrow);
    }

    @include ui-media-element-xl {
      max-width: var(--ui-layout-grid-max-width-xl-narrow);
    }
  }

  &.ui-row-lg-slender {
    @include ui-media-lg {
      max-width: var(--ui-layout-grid-max-width-lg-slender);
    }

    @include ui-media-element-lg {
      max-width: var(--ui-layout-grid-max-width-lg-slender);
    }
  }

  &.ui-row-lg-narrow {
    @include ui-media-lg {
      max-width: var(--ui-layout-grid-max-width-lg-narrow);
    }

    @include ui-media-element-lg {
      max-width: var(--ui-layout-grid-max-width-lg-narrow);
    }
  }

  &.ui-row-md-narrow {
    @include ui-media-md {
      max-width: var(--ui-layout-grid-max-width-md-narrow);
    }

    @include ui-media-element-md {
      max-width: var(--ui-layout-grid-max-width-md-narrow);
    }
  }
}

/**
Generic definition for columns.
This grid works by means of a 24-column structure.
An "offset" can be defined, if you need your content not to start at the beginning of a row.

----------
USAGE EXAMPLE:

<section class="ui-row">
  <div class="ui-col-12 ui-col-offset-6"></div>
  <div class="ui-col-3"></div>
  <div class="ui-col-3"></div>
</section>

Outcome:
- The first 6 columns in the row are empty
- The first "div" starts on the 7th column (leaving the first 6 empty) and takes up until the 18th column
- The second "div" starts on the 19th column and takes up until the 21st column
- The third "div" starts on the 22nd column and takes up until the 24th column
----------
 */
[class*='ui-col-'] {
  float: left;

  @include rtl() {
    float: right;
  }
  box-sizing: border-box;
}

@for $i from 1 through $cols {
  .ui-col-#{$i} {
    width: math.div(100%, $cols) * $i;
    padding: 0 calc(var(--ui-layout-grid-gutter-xs) / 2);
  }

  .ui-col-offset-#{$i} {
    margin-left: math.div(100%, $cols) * $i;

    @include rtl() {
      margin-left: auto;
      margin-right: math.div(100%, $cols) * $i;
    }
  }
}

/**
Definition for columns on specific resolutions.
This grid is MOBILE FIRST, therefore the media queries are defined basing on min-width.
This means that, if you need your content to fill up different amounts of space basing on screen resolution,
you need to specify different column classes on HIGHER resolutions, not lowest.

----------
USAGE EXAMPLE:

I have a dashboard with 4 widgets.
I want to display:
- 4 widgets per row on LG and XL resolutions
- 2 widgets per row on MD resolutions
- 1 widget per row on SM and XS resolutions

<section class="ui-row">
  <div class="ui-col-24 ui-col-md-12 ui-col-lg-6">
    <!-- My widget here -->
  </div>
  <div class="ui-col-24 ui-col-md-12 ui-col-lg-6">
    <!-- My widget here -->
  </div>
  <div class="ui-col-24 ui-col-md-12 ui-col-lg-6">
    <!-- My widget here -->
  </div>
  <div class="ui-col-24 ui-col-md-12 ui-col-lg-6">
    <!-- My widget here -->
  </div>
</section>
 */
@for $i from 1 through $cols {
  @include ui-media-xs {
    .ui-col-xs-#{$i} {
      width: math.div(100%, $cols) * $i;
    }

    .ui-col-offset-xs-#{$i} {
      margin-left: math.div(100%, $cols) * $i;

      @include rtl() {
        margin-left: auto;
        margin-right: math.div(100%, $cols) * $i;
      }
    }
  }

  @include ui-media-element-xs {
    .ui-col-xs-#{$i} {
      width: math.div(100%, $cols) * $i;
    }

    .ui-col-offset-xs-#{$i} {
      margin-left: math.div(100%, $cols) * $i;

      @include rtl() {
        margin-left: auto;
        margin-right: math.div(100%, $cols) * $i;
      }
    }
  }
}

@for $i from 1 through $cols {
  @include ui-media-sm {
    .ui-col-#{$i} {
      padding: 0 calc(var(--ui-layout-grid-gutter-sm) / 2);
    }

    .ui-col-sm-#{$i} {
      width: math.div(100%, $cols) * $i;
    }

    .ui-col-offset-sm-#{$i} {
      margin-left: math.div(100%, $cols) * $i;

      @include rtl() {
        margin-left: auto;
        margin-right: math.div(100%, $cols) * $i;
      }
    }
  }

  @include ui-media-element-sm {
    .ui-col-#{$i} {
      padding: 0 calc(var(--ui-layout-grid-gutter-sm) / 2);
    }

    .ui-col-sm-#{$i} {
      width: math.div(100%, $cols) * $i;
    }

    .ui-col-offset-sm-#{$i} {
      margin-left: math.div(100%, $cols) * $i;

      @include rtl() {
        margin-left: auto;
        margin-right: math.div(100%, $cols) * $i;
      }
    }
  }
}

@for $i from 1 through $cols {
  @include ui-media-md {
    .ui-col-#{$i} {
      padding: 0 calc(var(--ui-layout-grid-gutter-md) / 2);
    }

    .ui-col-md-#{$i} {
      width: math.div(100%, $cols) * $i;
    }

    .ui-col-offset-md-#{$i} {
      margin-left: math.div(100%, $cols) * $i;

      @include rtl() {
        margin-left: auto;
        margin-right: math.div(100%, $cols) * $i;
      }
    }
  }

  @include ui-media-element-md {
    .ui-col-#{$i} {
      padding: 0 calc(var(--ui-layout-grid-gutter-md) / 2);
    }

    .ui-col-md-#{$i} {
      width: math.div(100%, $cols) * $i;
    }

    .ui-col-offset-md-#{$i} {
      margin-left: math.div(100%, $cols) * $i;

      @include rtl() {
        margin-left: auto;
        margin-right: math.div(100%, $cols) * $i;
      }
    }
  }
}

@for $i from 1 through $cols {
  @include ui-media-lg {
    .ui-col-#{$i} {
      padding: 0 calc(var(--ui-layout-grid-gutter-lg) / 2);
    }

    .ui-col-lg-#{$i} {
      width: math.div(100%, $cols) * $i;
    }

    .ui-col-offset-lg-#{$i} {
      margin-left: math.div(100%, $cols) * $i;

      @include rtl() {
        margin-left: auto;
        margin-right: math.div(100%, $cols) * $i;
      }
    }
  }

  @include ui-media-element-lg {
    .ui-col-#{$i} {
      padding: 0 calc(var(--ui-layout-grid-gutter-lg) / 2);
    }

    .ui-col-lg-#{$i} {
      width: math.div(100%, $cols) * $i;
    }

    .ui-col-offset-lg-#{$i} {
      margin-left: math.div(100%, $cols) * $i;

      @include rtl() {
        margin-left: auto;
        margin-right: math.div(100%, $cols) * $i;
      }
    }
  }
}

@for $i from 1 through $cols {
  @include ui-media-xl {
    .ui-col-#{$i} {
      padding: 0 calc(var(--ui-layout-grid-gutter-xl) / 2);
    }

    .ui-col-xl-#{$i} {
      width: math.div(100%, $cols) * $i;
    }

    .ui-col-offset-xl-#{$i} {
      margin-left: math.div(100%, $cols) * $i;

      @include rtl() {
        margin-left: auto;
        margin-right: math.div(100%, $cols) * $i;
      }
    }
  }

  @include ui-media-element-xl {
    .ui-col-#{$i} {
      padding: 0 calc(var(--ui-layout-grid-gutter-xl) / 2);
    }

    .ui-col-xl-#{$i} {
      width: math.div(100%, $cols) * $i;
    }

    .ui-col-offset-xl-#{$i} {
      margin-left: math.div(100%, $cols) * $i;

      @include rtl() {
        margin-left: auto;
        margin-right: math.div(100%, $cols) * $i;
      }
    }
  }
}


.ui-ripple {
  overflow: hidden;
  position: relative;

  &.ui-ripple-unbounded {
    overflow: visible;
  }

  &.ui-ripple-negative {
    .ui-ripple-element {
      background-color: var(--ui-color-interaction-ripple-light);
    }
  }
}

.ui-ripple-element {
  position: absolute;
  border-radius: var(--ui-radius-circular);
  pointer-events: none;

  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
  background-color: var(--ui-color-interaction-ripple-dark);
}


:focus {
  outline: none;
}


@mixin ui-keyboard-navigation {
  .ui-keyboard-navigation & {
    @content;
  }
}

%ui-text-link {
  color: var(--ui-color-typography-accent);
  fill: var(--ui-color-typography-accent);
  font-weight: var(--ui-typography-weight-semibold);
  text-decoration: none;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }

  &:focus {
    text-decoration: underline;
  }

  @include ui-keyboard-navigation {
    &:focus {
      outline: solid 1px var(--ui-color-border-accessibility-default);
      outline-offset: var(--ui-spacing-micro);
    }
  }

  &:active {
    opacity: var(--ui-opacity-middle);
  }
}

%ui-text-link-negative {
  color: var(--ui-color-typography-negative);
  fill: var(--ui-color-typography-negative);
  font-weight: var(--ui-typography-weight-semibold);
  text-decoration: none;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }

  &:focus {
    text-decoration: underline;
  }

  @include ui-keyboard-navigation {
    &:focus {
      outline: solid 1px var(--ui-color-border-accessibility-negative);
      outline-offset: var(--ui-spacing-micro);
    }
  }

  &:active {
    background-color: var(--ui-color-interaction-hover-light);
  }
}

%ui-a11y-hover-idle {
  position: relative;

  @media (hover: hover) {
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: var(--ui-opacity-transparent);
      will-change: opacity;
      background-color: var(--ui-color-interaction-hover-dark);
      transition: opacity var(--ui-motion-duration-short)
        var(--ui-motion-easing-entering);
    }
  }
}

%ui-a11y-hover-active {
  @media (hover: hover) {
    &:before {
      opacity: var(--ui-opacity-full);
    }
  }
}

%ui-a11y-outline {
  display: block;
  position: absolute;
  top: var(--ui-spacing-micro);
  left: var(--ui-spacing-micro);
  width: calc(100% - var(--ui-spacing-tiny));
  height: calc(100% - var(--ui-spacing-tiny));
  box-sizing: border-box;
  opacity: 0;
  border: solid var(--ui-size-border-xs)
    var(--ui-color-border-accessibility-default);
  border-radius: var(--ui-radius-default);
  will-change: opacity;
  transition: opacity var(--ui-motion-duration-short)
    var(--ui-motion-easing-entering);
}


@mixin ui-tooltip-active {
  opacity: 1;

  transition: opacity var(--ui-motion-duration-micro)
      var(--ui-motion-easing-leaving),
    transform var(--ui-motion-duration-micro) var(--ui-motion-easing-leaving);

  &.ui-tooltip-above {
    transform: translateY(calc(0px - var(--ui-spacing-xxs)));
  }

  &.ui-tooltip-below {
    transform: translateY(var(--ui-spacing-xxs));
  }

  &.ui-tooltip-left {
    transform: translateX(calc(0px - var(--ui-spacing-xxs)));
  }

  &.ui-tooltip-right {
    transform: translateX(var(--ui-spacing-xxs));
  }
}

.ui-tooltip-ghost {
  width: 0;
  height: 0;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}

.ui-tooltip {
  position: fixed;
  display: block;
  opacity: 0;
  pointer-events: none;
  box-sizing: content-box;
  background-color: var(--ui-color-dialog-background-dark);
  color: var(--ui-color-typography-negative);
  padding: var(--ui-spacing-micro) var(--ui-spacing-xxs);
  border-radius: var(--ui-radius-default);
  will-change: transform, opacity, top, left;
  z-index: var(--ui-zindex-onthetop);
  max-width: var(--ui-size-modal-xxs);
  text-align: center;
  word-break: break-word;

  transition: opacity var(--ui-motion-duration-short)
      var(--ui-motion-easing-entering),
    transform var(--ui-motion-duration-short) var(--ui-motion-easing-entering);

  &:not(.ui-tooltip-disabled) {
    &.ui-tooltip-always-visible,
    &.ui-tooltip-triggered {
      @include ui-tooltip-active;
    }
  }
}

