@import '../../scss/mixins';

.learning-plan-page {
    @include paddingAmongBreadcrumb;

    .content {
        margin-top: var(--ui-spacing-xs);
    }

    .cards-list-container {
        margin-bottom: 1px;
    }
}

.spinner-lp {
    margin-top: var(--ui-spacing-md);
}
