@mixin text-ellipsis($lineClamp: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lineClamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

//Padding between breadcrumb and page content
@mixin paddingAmongBreadcrumb {
    padding-top: var(--ui-spacing-md);
}
