@import "../../../scss/mixins";

.ate-card-list-item {
  padding: var(--ui-spacing-xxs) !important;
  width: 100% !important;
  border: var(--ate-widget-border-style);

  &.locked {
    cursor: default;

    .container {
      display: flex;
      align-items: center;
      background: var(--ui-color-typography-primary);
      border-radius: 50%;
      padding: var(--ui-spacing-xxs);
    }
  }

  & .list-item-preview-wrapper {
    flex-grow: 0 !important;
  }

  & .list-item-preview-container {
    position: relative;
    overflow: hidden;
    height: 66px;
    margin: 0 !important;
    width: 132px !important;

    & .list-item-image {
      background-image: var(--course-background-image);
      background-position: center;
      background-size: cover;
      border-radius: var(--ate-border-radius);
      height: 100%;
      width: 100%;
    }

    & .locked {
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;

      & .locked-icon {
        color: white;
      }
    }

    & .list-item-left-label {
      position: absolute;
      left: var(--ui-spacing-tiny);
      top: var(--ui-spacing-tiny);
    }

    & .list-item-right-label {
      position: absolute;
      right: var(--ui-spacing-tiny);
      top: var(--ui-spacing-tiny);
    }
  }

  & .list-item-body {
    padding: var(--ui-spacing-xs);

    & .list-item-title {
      @include text-ellipsis(1);
    }

    & .list-item-available {
      @include text-ellipsis(1);
      margin-bottom: var(--ui-spacing-tiny);
    }

    & .list-item-details-divider {
      margin: 0 var(--ui-spacing-xxs);
    }

    & .list-item-rating {
      margin-right: var(--ui-spacing-xs);

      .ate-icon {
        margin-bottom: var(--ui-spacing-micro);
      }

      .number {
        margin-left: var(--ui-spacing-tiny);
      }
    }
  }

  & .list-item-footer {
    position: absolute;
    top: 0;
    right: 0;
    height: var(--ui-spacing-xl);
    margin-top: var(--ui-spacing-xs);
    padding: 0 var(--ui-spacing-xs);
    flex-grow: 1;
  }
}
