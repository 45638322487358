.databrowser-search-container {
    &.full-mode {
        background-color: var(--ate-background-color);
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;

        .input {
            width: 100%;
        }
    }

    .input-container {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 var(--ui-spacing-xs);

        .search-button {
            cursor: pointer;
        }
    }
}
