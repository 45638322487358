@import "../../scss/variables";
@import "../../scss/mixins";

.ate-breadcrumb {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--ui-color-border-baseline);
    height: var(--ate-breadcrumb-height);

    &.hidden {
        display: none;
    }

    .breadcrumb-desktop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        position: relative;
        padding: 0 var(--ui-spacing-xs);
        overflow: hidden;

        &.hidden {
            display: none;
        }

        .navigation {
            width: 100%;
        }

        &.has-filters {
            overflow: visible;

            .navigation {
                padding-inline-end: var(--ui-spacing-xs);
                border-right: 1px solid var(--ui-color-border-baseline);
                width: unset;
            }
        }

        .filters {
            padding: var(--ui-spacing-xs);
        }

        .breadcrumb-item-selected {
            overflow: hidden;

            .breadcrumb-button {
                justify-content: flex-start;
            }

            .breadcrumb-button-selected {
                @include text-ellipsis(1);
                text-overflow: ellipsis;
            }
        }
    }

    .breadcrumb-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        position: relative;
        height: 100%;
        border-top: 1px solid var(--ui-color-border-baseline);

        &.hidden {
            display: none;
        }

        &.full-width {
            .navigation {
                border-right: unset;
                flex: 1;
            }
        }

        .navigation {
            display: flex;
            height: 100%;
            align-items: center;
            border-right: 1px solid var(--ui-color-border-baseline);
            padding-inline: var(--ui-spacing-xxs);

            .item-selected-button {
                border: 0;
                width: 100%;
                display: inline-flex;
                justify-content: space-between;
            }
        }

        .filters {
            display: flex;
            justify-content: center;
            flex: 1;
            padding-inline: var(--ui-spacing-xxs);
        }
    }
}

.menu-item {
    min-width: 100%;

    .item-container {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .item-content {
            display: inline-block;
            padding-inline-end: var(--ui-spacing-xxs);
        }

        .item-icon {
            display: inline-block;
        }
    }
}

.full-width-mobile-menu {
    // we need this in order to overwrite a rule from Fluent library
    min-width: calc(100% - var(--ui-spacing-sm)) !important;
}
