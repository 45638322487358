.dcbo-course-player {
  border-top: 1px solid var(--ate-course-player-border);

  .course-player-viewer {

    .course-player-viewer-head {
      background-color: var(--ate-background-color);

      .course-player-viewer-head-content-title {
        color: var(--ate-text-color);
      }

      .course-player-viewer-head-content-subtitle {
        color: var(--ate-heading-color);
      }

      .ui-icon span svg {
        fill: var(--ate-text-color);
      }

      .course-player-viewer-head-actions {

        .dcb-ui-button-outlined.ui-button-disabled {
          color: var(--ate-text-color);
          border-color: var(--ate-text-color);

          .ui-ripple {
            opacity: var(--ate-button-opacity-disabled);

            &:hover {
              border-color: var(--ate-course-player-item-hover-disabled);
              background-color: var(--ate-background-color);

              .ui-button-outlined-content {
                color: var(--ate-course-player-item-hover-disabled);
              }

              .ui-icon span svg {
                fill: var(--ate-course-player-item-hover-disabled);
              }
            }
          }
        }

        .ui-ripple:hover {
          background-color: var(--ate-course-player-item-hover-background);
          border-color: var(--ate-course-player-item-hover-border);

          &:before {
            background-color: var(--ate-course-player-item-hover-enabled);
          }
        }

        .ui-ripple:hover .ui-button-outlined-content {
          color: var(--ate-course-player-item-hover-heading);
        }

        .ui-ripple:hover .ui-icon span svg {
          fill: var(--ate-course-player-item-hover-heading);
        }
      }
    }

    .course-player-viewer-body {

      .course-player-viewer-body-internal-toc {
        background-color: var(--ate-background-color);
      }
    }

    .toc {

      .toc-head {

        .toc-head-info {
          background-color: var(--ate-background-color);
          color: var(--ate-text-color);

          .font-secondary {
            color: var(--ate-text-color);
          }

          .course-info-name {
            color: var(--ate-heading-color)
          }

          .ui-rating-value-number {
            color: var(--ate-text-color);
          }

          .detail-list-item {
            border-color: var(--ate-text-color);
          }

          .toc-head-info-course-name {
            color: var(--ate-heading-color);
          }

          .error {
            color: var(--ate-expiration-date-color);
          }
        }
      }

      .toc-body {

        .toc-overview-head {
          color: var(--ate-heading-color);
        }

        .toc-overview-body {
          color: var(--ate-text-color);
          padding-bottom: var(--ui-spacing-md);
        }

        .tabs-horizontal-wrapper {

          .tabs-horizontal-panels {

            .ui-list-item-wrapper {
              background-color: var(--ate-background-color);

              .ui-card-content {
                position: relative;

                .ui-color-typography-primary {
                  color: var(--ate-heading-color);
                }

                .ui-color-typography-secondary {
                  color: var(--ate-text-color);
                }
              }

              &:hover {

                .ui-color-typography-primary {
                  color: var(--ate-course-player-item-hover-heading);
                }

                .ui-color-typography-secondary {
                  color: var(--ate-course-player-item-hover-text);
                }
              }
            }

            .ui-list-item-input-locked {

              .ui-color-typography-primary {
                color: var(--ate-course-player-item-hover-disabled);
              }

              .ui-color-typography-secondary {
                color: var(--ate-course-player-item-hover-disabled);
              }

              &:hover {

                .ui-color-typography-primary {
                  color: var(--ate-course-player-item-hover-disabled);
                }

                .ui-color-typography-secondary {
                  color: var(--ate-course-player-item-hover-disabled);
                }
              }
            }
          }

          .dcb-ui-tabs-horizontal-header {
            background-color: var(--ate-background-color);
            color: var(--ate-heading-color);

            .dcb-ui-tabs-horizontal-header-item {
              color: var(--ate-text-color);
            }

            .dcb-ui-tabs-horizontal-header-item-active {
              color: var(--ate-horizontal-tab-active);
            }

            .dcb-ui-tabs-horizontal-header-active-item-edge {
              background-color: var(--ate-selection-color);
            }

            .dcb-ui-tabs-horizontal-header-control {
              background-color: var(--ate-background-color);

              .ui-icon span svg {
                fill: var(--ate-text-color);
              }
            }
          }

          .tabs-horizontal-panels {
            background-color: var(--ate-background-color);
            color: var(--ate-text-color);

            .toc-overview-head {
              color: var(--ate-heading-color);
            }

            .toc-overview-body {
              color: var(--ate-text-color);
            }
          }
        }

        .ui-list-item-wrapper:before {
          background-color: var(--ate-course-player-item-hover-background);
        }
      }
    }

    .dcb-ui-button-outlined button.color-neutral {
      color: var(--ate-text-color);
      border-color: var(--ate-text-color);
    }
  }

  iframe {
    background-color: #FFFFFF;
  }

  // Blank slate
  flow-course-player-blank-slate {
    background-color: var(--ate-background-color);

    .ui-blank-slate {
      color: var(--ate-heading-color);
    }

    .back {
      color: var(--ate-course-player-link) !important;
    }
  }

  // Rules for mobile view
  .toc.toc-head-small .toc-head {
    background-color: var(--ate-background-color);

    .toc-head-info {

      .toc-head-info-course-name {
        color: var(--ate-heading-color);
      }
    }
  }

  .dcb-ui-tabs-horizontal {
    background-color: var(--ate-background-color);

    .dcb-ui-tabs-horizontal-header {
      background-color: var(--ate-background-color);

      .dcb-ui-tabs-horizontal-header-item {
        color: var(--ate-text-color);
      }

      .dcb-ui-tabs-horizontal-header-item-active {
        color: var(--ate-horizontal-tab-active);
      }

      .dcb-ui-tabs-horizontal-header-active-item-edge {
        background-color: var(--ate-selection-color);
      }
    }
  }

  .toc-body .dcb-ui-tabs-horizontal .tabs-horizontal-wrapper .tabs-horizontal-panels {
    background-color: var(--ate-background-color);

    .toc-overview-head {
      color: var(--ate-heading-color);
    }

    .toc-overview-body {
      color: var(--ate-text-color);
    }
  }

  .toc-footer {
    background-color: var(--ate-background-color);
  }

  .ui-list-item-wrapper {
    background-color: var(--ate-background-color);

    .ui-color-typography-primary {
      color: var(--ate-heading-color);
    }

    .ui-color-typography-secondary {
      color: var(--ate-text-color);
    }
  }

  .toc-head {

    .toc-head-info {

      .ui-icon span.color-accent-primary svg {
        fill: var(--ate-selection-color);
      }
    }
  }
}

// the breadcrumb already has a border,
// so we unset the course player one when the breadcrumb is visible
.breadcrumb-visible {
  .dcbo-course-player {
    border-top: unset;
  }
}
