.databrowser-filters-panel {
    .button-container {
        position: relative;
    }

    .panel-container {
        display: none;
        position: absolute;
        min-width: 220px;
        border-radius: var(--ate-border-radius);
        margin-top: 6px;
        z-index: 2;
        padding: 0;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 36px;
            padding: 0 20px;

            .clear {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .filter-list {
            display: flex;
            flex-direction: column;
            padding: 0 var(--ui-spacing-xs);

            .single-filter {
                display: flex;
                align-items: center;
                width: 100%;
                height: 40px;

                .checkbox-filter {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }

    &.active {
        .panel-container {
            display: block;
            border: 1px solid var(--ate-widget-border);
        }
    }
}
